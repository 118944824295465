/* .experience-snippet {
    padding: 0;
} */

.experience-snippet-inner {
	width: 100%;
	padding: 0.75rem 0;
	margin-top: 2rem;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
	background: var(--background_secondary);
	color: var(--fontColor-primary);
	display: flex;
	justify-content: space-evenly;
	border-radius: 30px;
	transition: 0.2s ease-in-out;
}

.experience-snippet-inner:hover {
	transform: scale(1.05);
}

.snippet-image {
	width: max(80px, 17%);
	display: flex;
	/* justify-content: center; */
	align-items: center;
}

.snippet-img-snippets {
	--dimension: clamp(80px, 100%, 130px);
	width: var(--dimension);
	height: var(--dimension);
	border-radius: 50%;
}

.snippet-content {
	color: var(--fontColor-primary);
	width: 65%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.snippet-content h5 {
	font-family: Nunito;
	font-family: TTNorms-Black;
	font-style: normal;
	font-weight: 600;
	color: var(--fontColor-primary);
	margin-top: 15px;
	margin-bottom: 5px;
	text-decoration: none !important;
}

.snippet-content p {
	color: var(--fontColor-secondary);
	margin-bottom: 0.25rem;
}

.snippet-content a {
	text-decoration: none !important;
}

.snippet-content-main {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: clamp(10px, 1vw, 30px);
}

.snippet-content-main_sub-details {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.snippet-event-type {
	padding: 0.25rem 1rem;
	color: var(--fontColor-primary);
	background-color: var(--background_tertiary);
	font-size: 0.8rem;
	letter-spacing: calc(1px + 0.1vw);
	font-weight: 600;
	border-radius: 10px;
}

.snippet-content-not-hero {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.duration-in-snippet {
	margin: 0;
	font-family: Nunito;
	font-family: TTNorms-Black;
	color: var(--fontColor-secondary);
}

.snippet-content-head {
	display: flex;
	align-items: center;
	gap: max(10px, calc(5px + 1vw));
	justify-content: space-between;
	flex-wrap: wrap;
}

.snippet-interested {
	font-size: 0.75rem;
}

.snippet-interested-img {
	--dimension: clamp(15px, 5vw, 20px);
	width: var(--dimension);
	height: var(--dimension);
	border-radius: 50%;
}

.snippet-interested-img + .snippet-interested-img {
	margin-left: -10px;
}

.snippet-attend-btn {
	padding: 10px 25px;
	border-radius: 20px;
	background: none;
}

.snippet-attend-btn:hover {
	color: white;
	background: var(--background_gradient);
}

@media only screen and (max-width: 576px) {
	.snippet-content-main {
		flex-direction: column;
		align-items: flex-start;
		gap: max(5px, calc(5px + 0.75vw));
	}

	.snippet-content-main,
	h5 {
		font-size: 1.15rem;
	}

	.snippet-attend-btn {
		padding: 5px 15px;
		font-size: 0.9rem;
	}

	.snippet-content-main_sub-details {
		/* flex-direction: row; */
		/* align-items: center; */
		justify-content: flex-start;
		/* gap: 15px; */
		font-size: 0.75rem;
	}
}

.eventCard {
    box-shadow: var(--shadow_primary);
    background: linear-gradient(45deg, var(--background_primary), var(--background_secondary));
    border-radius: 30px;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.left {
    display: flex;
    align-items: center;
    gap: 20px;
}

.date {
    box-shadow: var(--shadow_primary);
    padding: 1px 10px;
    padding-bottom: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date p {
    margin: 0 !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.date p:first-child {
    position: relative;
    top: 5px;
}

.content h5 {
    margin: 0;
}

.features {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 15px;
}

.feature {
    padding: 5px 10px;
    box-shadow: var(--shadow_primary);
    border-radius: 30px;
    font-size: 14px;
}

.right .date {
    display: none;
}

.right {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width:500px) {
    .eventCard {
        flex-direction: column;
        align-items: flex-start;
    }

    .features {
        gap: 5px;
    }

    .feature {
        font-size: 12px;
    }

    .left .date {
        display: none;
    }

    .right {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-direction: row;
    }

    .right .date {
        display: flex;
    }

    .right button {
        height: 35px;
    }

}
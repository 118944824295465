.show-more {
	font-size: 1.25rem;
	padding: 0.25rem 4rem;
	border-radius: 10px;
	width: fit-content;
	text-align: center;
	margin: auto;
}

.top-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	justify-content: center;
}

.top-row button {
	background: none;
	padding: 0.5rem 1rem;
	width: fit-content;
	border-radius: 10px;
	font-size: 1rem;
	font-weight: bold;
	font-family: Gopher-Regular;
	text-transform: capitalize;

}

.dropdown {
	box-shadow: none !important;
}

.dropdown-menu {
	inset: 0.5rem auto auto auto !important;
	background-color: var(--background_secondary) !important;
	text-align: center;
	padding: 0.25rem 0.5rem;
	border: 1px solid var(--border);
	border-radius: 1rem;
}

.dropdown-menu button {
	border-radius: 10px;
	margin: 0.25rem 0;
	width: 100%;
	color: var(--fontColor-primary) !important;
	background: var(--background_primary) !important;
	border: 1px solid var(--fontColor-secondary);
}

.all-sub {
	padding: 0.5rem 1rem;
	border-radius: 10px;
	margin: 10px 0;
}

/* Pagination Styles */

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.25rem;
}

.pagination ul {
	gap: 0.5rem;
	flex-wrap: wrap;
	justify-content: center;
}

.pagination li a {
	width: 2.75rem;
	height: 2.75rem;
	border: 0;
	border-radius: 50%;
	color: var(--fontColor-secondary);
	background-color: var(--background_tertiary) !important;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Gopher-Regular;
}

.form-check {
	font-family: Gopher-Regular !important;
}

.pagination li:first-child a,
.pagination li:last-child a {
	border-radius: 50% !important;
}

.pagination li a:hover {
	color: var(--fontColor-primary) !important;
}

.dropdown {
	border-color: var(--border) !important;
	box-shadow: none !important;
}

.dropdown-menu {
	inset: 0.5rem auto auto auto !important;
	background-color: var(--background_secondary) !important;
	text-align: center;
	padding: 0.25rem 0.5rem;
	border: 1px solid var(--border);
	border-radius: 1rem;
}

.dropdown-menu button {
	border-radius: 10px;
	margin: 0.25rem 0;
	color: var(--fontColor-primary) !important;
	background: var(--background_tertiary) !important;
	border: 1px solid var(--fontColor-secondary);
}

.is-active {
	border: 1px solid var(--border) !important;
}

.serachBox {
	width: 100%;
}

.serachBox input {
	width: 100%;
	border: 2px solid;
	border-radius: 21px;
	padding: 10px;
	outline: none;
}

.serachBox i {
	position: absolute;
	right: 45px;
	top: 30px;
	font-size: 20px;
}

@media only screen and (max-width: 768px) {
	.top-row {
		padding: 0 !important;
	}
}
.mentorImage{
    height: 70px;
    width: 70px;
    border-radius: 50%;
}

.mentorItemLeft{
    display: flex;
    flex: 1;
}

.mentorDetails{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mentorDetails h6, p{
    margin: 0;
}

.mentorDetails p {
    font-size: 12px!important;
    margin-top: 2px;
}

.mentorItemRight  {
    display: flex;
    align-items: center;
}

.mentorItemRight a {
    color: #56ccf2;
    text-decoration: none;
    font-size: 14px;
    position: relative;
    bottom:6px;
}
.card {
    box-shadow: var(--shadow_primary);
    background: linear-gradient(45deg, var(--background_primary), var(--background_secondary));
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 30px;
    cursor: pointer;
}

.cardImg {
    overflow: hidden;
    border-radius: 30px
}

.card:hover .cardImg img  {
    transform: scale(1.05);
}

.cardImg img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-radius: 30px;
    transition: 0.3s ease
}

.cardContent {
    padding: 15px 10px;
}

.cardContent h5 {
    margin: 0;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.features {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 13px 0;
}

.feature {
    padding: 5px 10px;
    box-shadow: var(--shadow_primary);
    border-radius: 30px;
    font-size: 14px;
}

.price {
    margin: 0;
    padding-left: 5px;
}


@media only screen and (max-width:500px) {

    .features {
        gap: 5px;
    }

    .feature {
        font-size: 12px;
    }

}
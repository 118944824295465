.activity {
	background-color: var(--background_primary);
	border-radius: 1.5rem;
	padding: 1.25rem;
	margin-left: 13px;
	margin-top: 13px;
	margin-bottom: 20px;
}

.individual-activity {
	padding: 1rem;
	border-bottom: 1px solid #8c8c926e;
	
}

.individual-activity:last-child {
	border-bottom: none;
}

.clubMembers {
	position: fixed;
	top: 185px;
	right: 3%;
}

.clubMembersInner {
	background-color: var(--background_primary);
	box-shadow: var(--shadow_primary);
	border-radius: 30px;
	padding: 20px;
	width: 280px;
	max-height: 80vh;
	overflow: auto;
	z-index: -1;
}

.clubMembersInner::-webkit-scrollbar {
	display: none;
}

.clubMembersWrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	height: 100%;
}

.clubMember{
	cursor: pointer;
}


@media only screen and (max-width:1100px) {
	.clubMembers{
		display: none!important;
	}

}

@media only screen and (max-width:770px) {
	.activity {
		margin-left: 0px;
		margin-top: 0px;
	}

	.individual-activity {
		overflow-x: hidden;
	}
}

@media only screen and (max-width:500px) {
	.individual-activity {
		padding: 1rem 0;
		overflow-x: hidden;
	}

}
.content {
	background: var(--background_primary);
	height: fit-content;
	border-radius: 30px;
	padding: 1rem;
}

.title {
	padding: 1rem 0;
	margin-bottom: 1rem;
	font-weight: 500;
	font-size: 1.25em;
	border-bottom: 2px solid grey;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title button {
	padding: 0;
	height: 1.5rem;
	width: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--fontColor-primary);
	border-radius: 50%;
}

.action {
	font-size: 1.1rem;
	padding: 0.25rem 0.75rem;
	width: fit-content;
	align-self: flex-end;
	margin-right: 1rem;
}

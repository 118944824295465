.carousel-container {
	margin: 12px 0 20px;
}

.card-container {
	position: relative;
	border-radius: 1.5rem;
	margin: 0 10px;
	background: linear-gradient(
		to bottom,
		transparent 0%,
		rgba(142, 45, 226, 0.3) 35%,
		rgb(142, 45, 226) 100%
	);
	overflow: hidden;
}

.card-img {
	position: relative;
	width: 100%;
	border-radius: 1.5rem;
	z-index: -1;
}

.details {
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	display: flex;
	flex-direction: column;
	color: white;
}

.details p {
	margin: 0;
	margin-bottom: 0.25rem;
}

.details img {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	margin-right: 0.5rem;
}

@media screen and (max-width: 700px) {
	.card-img {
		width: 85vw !important;
		margin-left: -11px;
	}
}

.expContent {
	margin-top: 1rem;
	padding: 0;
	margin-left: -20px
}

.expContent ul {
	display: flex !important;
}

.expContent ul li {
	list-style: none;
	margin-right: 30px;
	color: var(--fontColor-primary);
	border-bottom: 2px solid var(--border);
	font-weight: bold;
	cursor: pointer;
}

.scrollToTop {
	display: inline;
	position: fixed;
	top: 90%;
	right: 2%;
}

@media only screen and (max-width : 768px) {
	.scrollToTop {
		top: 82%
	}
}
@font-face {
	font-family: "AvenirNext";
	/*src: url('./fonts/AvenirNextLTPro-Regular.otf');*/
	src: url("./fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
}

@font-face {
	font-family: "Source Sans Pro";
	src: url("./fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
}

@font-face {
	font-family: "Nunito";
	src: url("./fonts/Nunito/Nunito-Regular.ttf");
}

@font-face {
	font-family: "TTNorms-Black";
	src: url("./fonts/TT-Norms-Fonts/TTNorms-Regular.otf");
}

@font-face {
	font-family: "Gopher-Regular";
	src: url("./fonts/Gopher/Gopher-Regular.ttf");
}


* {
	transition: background-color 0.3s ease-in-out;
}

:root {
	--background_gradient: radial-gradient(circle farthest-side at top left,
			#3662f1 0%,
			#b71cf6 80%);
	--border: #b71cf6;
	--input-border_radius: 1.75rem;
}

.App {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	/* background: var(--background_primary); */
}

.dark-bg-pri {
	background-color: var(--background_primary);
}

.dark-bg-sec {
	background-color: var(--background_secondary);
}

.dark-bg-ter {
	background-color: var(--background_tertiary);
}

.dark-bg-gra {
	background-color: var(--background_gradient);
}

.dark-border {
	border-color: var(--border);
	border-style: solid;
}

.dark-font-pri {
	color: var(--fontColor-primary);
}

.dark-font-sec {
	color: var(--fontColor-secondary);
}

body {
	font-family: "Gopher-Regular";
	color: var(--fontColor-primary);
	background-color: var(--background_primary);
	box-sizing: border-box;
}

button {
	background: var(--background_gradient);
	color: var(--fontColor-primary);
	border: none;
	/* border-color: var(--border); */
	/* border-style: solid; */
	text-transform: uppercase;
	font-weight: 700 !important;
	border-radius: 10px;

}

button:not(.nav-link, .btn-close, .no-hover):hover {
	background: radial-gradient(circle farthest-side at top left, #b71cf6 0%, #3662f1 80%);
}

button:disabled {
	background-color: var(--background_gradient);
	color: var(--fontColor-primary);
	filter: grayscale(90%);
	text-transform: uppercase;
	font-weight: normal !important;
	border-radius: 10px !important;
}

.form-control:disabled {
	background-color: var(--background_primary);
	color: var(--fontColor-primary);
	opacity: 0.8;
}

input {
	background-color: var(--background_secondary);
	color: var(--fontColor-primary);
}

header {
	position: sticky;
	top: 0;
	z-index: 1000;
	font-weight: 700;
	font-family: "Gopher-Regular";
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
}

/* @media only screen and (max-width:768px) {
	header {
		position: static;
	}
} */

.form-control {
	border-radius: var(--input-border_radius);
}

.form-control,
.form-control:focus {
	background-color: var(--background_secondary);
	color: var(--fontColor-primary);
	border: none;
}

.form-select {
	background-color: var(--background_secondary);
	color: var(--fontColor-primary);
	border: none;
	border-radius: var(--input-border_radius);
}

.react-datepicker-wrapper {
	width: 100%;
}

.dob-picker-w {
	position: relative;
}

.dob-picker-w p {
	position: absolute;
	z-index: 1;
	padding-left: 12px;
	padding-top: 8px;
	font-weight: 600;
	font-size: 12px;
	color: var(--fontColor-secondary);
}

.dob-picker {
	background-color: var(--background_secondary);
	color: var(--fontColor-primary);
	border: none;
	border-radius: var(--input-border_radius);
	cursor: pointer;
	box-shadow: var(--shadow_primary);
	height: 58px;
	width: 100%;
	padding-left: 12px;
	padding-top: 15px;
}

.react-datepicker {
	border: none;
	box-shadow: var(--shadow_primary);
	border-radius: 20px;
}

.react-datepicker__month-container,
.react-datepicker__month,
.react-datepicker__day,
.react-datepicker__header,
.react-datepicker__current-month,
.react-datepicker__current-month--hasYearDropdown,
.react-datepicker__current-month--hasMonthDropdown,
.react-datepicker__month-select,
.react-datepicker__year-select,
.react-datepicker__day-name {
	background-color: var(--background_primary);
	color: var(--fontColor-primary);
}


.react-datepicker__day--selected {
	background-color: #3b40dd;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	border-radius: 0.3rem;
	background-color: #3b40dd;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	cursor: default;
	opacity: 0.5;
}

.swal-modal {
	background-color: var(--background_primary);
}

.swal-title,
.swal-text {
	color: var(--fontColor-primary);
}

.swal-button {
	border-radius: 10px;
}

.swal-button:focus {
	box-shadow: none;
}

.gradient-text-google {
	background: linear-gradient(45deg, #0000FF 20%, #00FF00 25%, #FFFF00 50%, red 70%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

hr {
	margin: 0;
	padding: 20px 0;
	background-color: var(--background_secondary);
	border-width: 2px;
	border-color: var(--background_secondary);
}

input:focus,
select:focus,
textarea:focus,
button:focus,
div:focus {
	outline: none;
}

.hide-it {
	display: none;
}

.bold-item {
	font-weight: 600;
}

.no-margin {
	margin-left: 0px;
	margin-right: 0px;
}

.capitalize-text {
	text-transform: capitalize;
}

.nav li {
	padding: 15px 0;
	text-transform: uppercase;
}

.underline-header {
	border-bottom: 2px solid var(--border);
	color: var(--fontColor-primary);
}

.underline-header:hover {
	border-bottom: 2px solid var(--border) !important;
}

.underline-header a:hover {
	color: var(--border) !important;
}

.normal-header a:hover {
	color: var(--border) !important;
}

.login-btn-header {
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 18px;
	color: white;
}

.normal-header a {
	padding: 15px 0px 10px 5px !important;
	color: var(--fontColor-primary) !important;
	text-transform: none !important;
}

.underline-header a {
	padding: 15px 0px 10px 5px !important;
	color: var(--fontColor-primary);
	font-weight: 600 !important;
	font-size: 15px;
	text-transform: none !important;
}

.nav li {
	margin-right: 2vw;
}

.nav li:hover {
	color: var(--border) !important;
}

.nav li:hover a {
	color: var(--border) !important;
	/* background-color: var(--background_primary) !important; */
}

.nav li:hover {
	/* background-color: var(--background_primary) !important; */
	color: var(--border) !important;
}

.no-padding {
	padding-left: 0px;
	padding-right: 0px;
}

.nav {
	margin: 0;
	padding: 0;
	list-style: none;
}

.nav a {
	display: inline-block;
	text-decoration: none;
	padding: 0.8em 1.8em;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 1.15rem;
	position: relative;
}

.nav {
	vertical-align: top;
	display: inline-block;
	border-radius: 6px;
}

.nav li {
	position: relative;
}

.nav>li {
	float: left;
	/*border-bottom: 4px #aaa solid; */
	margin-right: 1px;
}

.nav>li>a {
	margin-bottom: 1px;
	display: inline-block;
	text-transform: uppercase;
}

.nav>li:hover,
.nav>li:hover>a {
	border-bottom-color: var(--border);
}

.nav li:hover>a {
	color: var(--border);
}

header {
	color: var(--fontColor-primary);
	background-color: var(--background_primary);
	box-shadow: 0 0 3px 0 grey;
}

.header-div {
	width: 100%;
	height: 60px;
	/* margin-bottom: 0.5rem; */
	/* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08); */
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4vw;
	position: relative;
}

.notification-image::after {
	content: "";
	position: absolute;
	top: 15px;
	right: 65px;
	width: 13px;
	height: 13px;
	background-color: red;
	border-radius: 50%;
}

.notification-image-hidden::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	background-color: transparent;
	border-radius: 50%;
}


.header-content-container {
	justify-content: flex-end;
}

.subheader-div {
	margin: 1rem 0;
	width: fit-content;
	z-index: 100;
}

.subheader-div a {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 14px;
	text-decoration-line: none;
	color: var(--fontColor-secondary);
}

@media screen and (max-width: 768px) {
	.subheader-div {
		position: fixed;
		margin: 0;
		top: 1rem;
		left: 1rem;
	}

	.subheader-div button {
		padding: 0;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
	}

	.notification-image::after {
		content: "";
		position: absolute;
		top: 15px;
		right: 65px;
		width: 11px;
		height: 11px;
		background-color: red;
		border-radius: 50%;
	}
}

.header-logo {
	height: 40px;
	display: inline-block;
	position: relative;
	top: 5px;
}

@media only screen and (max-width:600px) {
	.notification-image::after {
		top: 10px;
	}

	.logo-div {
		width: 190px;
	}

	.header-logo {
		height: 40px;
		display: inline-block;
		position: relative;
		top: 5px;
		left: -30px;
		z-index: 10;
	}

	.dark-mode-toggle {
		z-index: 11;
	}
}


.inner-avatar-header-div {
	position: relative;
	display: inline-block;
}

.inner-avatar-header-div .dropdown-content-active {
	display: block;
	position: absolute;
	background-color: var(--background_primary);
	min-width: 210px;
	right: 5%;
	z-index: 10;
	border-radius: 30px;
}

.inner-avatar-header-div a {
	text-decoration: none;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: var(--background_primary);
	min-width: 210px;
	right: 5%;
	z-index: 10;
}

.dropdown-content a {
	color: var(--fontColor-primary);
}

.dropdown-content-active a {
	color: var(--fontColor-primary);
}

.scale-on-hover:hover {
	transition: 0.4s ease;
	transform: scale(1.03);
}

.desc {
	padding: 1rem;
}

.avatar-icon {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	display: inline-block;
	cursor: pointer;
	position: relative;
}

.logo-div {
	display: inline-block;
	margin-bottom: 10px;
}

.avatar-header-div {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}

.header-links {
	display: inline-block;
	vertical-align: middle;
}

li.ev-navbar-item {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.header-icon {
	color: var(--fontColor-primary);
	height: 20px;
	display: inline-block;
	margin-top: -4px;
	margin-right: 5px;
}

.ev-navbar-item a {
	font-size: 0.9rem;
}

.card {
	background-color: transparent;
}

.custom-20-percent,
.custom-25-percent {
	margin-bottom: 10px;
}

@media only screen and (max-width: 1150px) {
	.big-nav {
		display: none;
	}
}

@media only screen and (min-width: 1150px) {
	.mobile-scroll-menu {
		display: none !important;
	}

	.header-content-container {
		justify-content: space-between;
	}

	.login-btn-header {
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 25px;
	}

	.custom-20-percent {
		width: 20%;
		display: inline-block;
		padding-left: 10px;
		padding-right: 10px;
	}

	.custom-25-percent {
		width: 25%;
		display: inline-block;
		padding-left: 10px;
		padding-right: 10px;
	}

	.home-container {
		/* background-color: var(--background_primary); */
		color: var(--fontColor-primary);
		padding: 2vw 1vw;
		min-height: 20vh;
	}

	.margin-minus-twenty-five-desktop {
		margin-left: -25px !important;
		margin-right: -25px !important;
	}

	.margin-minus-fifteen-desktop {
		margin-left: -15px !important;
		margin-right: -15px !important;
	}

	.swal-interest {
		width: 50%;
	}
}

@media only screen and (max-width: 768px) {
	.login-btn-header {
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 15px;
	}

	.swal-payment-conf {
		width: auto !important;
	}

	.swal-payment-conf2 {
		width: auto !important;
	}

	.payment-popup-content-conf {
		padding: 10px 20px 10px 20px !important;
	}

	.exp-title-payment-conf {
		font-size: 1.25rem;
	}

	.exp-language-payment-conf {
		margin-top: 5px !important;
	}

	.date-payment-popup-conf {
		margin-top: 10px !important;
		font-size: 12px;
	}

	.home-container {
		padding: 10px 20px 10px 20px;
	}

	.snippet-image-single-page {
		/*height: 345px;*/
		margin-left: 0px !important;
	}
}

/* Homepage CSS */

.no-upcoming-webinar {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
}

.course-skeleton {
	background-color: var(--background_secondary);
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 40px;
	overflow: hidden;
	line-height: 1;
	width: 100%;
}

@media (min-width: 768px) {
	.course-skeleton {
		width: calc(45%);
	}
}

@media (min-width: 998px) {
	.course-skeleton {
		width: calc(30%);
	}
}

.course-skeleton-content {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
}

.course-skeleton-content h1,
.course-skeleton-content p {
	margin: 0;
}

.home-title {
	font-size: 26px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #444444;
	padding-bottom: 20px;
}

.webinar-snippet h3 {
	font-size: 24px !important;
	font-weight: 500 !important;
}

.snippet-image-single-page {
	/*height: 345px;*/
	margin-left: -10px;
}

.snippet-image-webinar-page {
	/*height: 500px;*/
	margin-left: -10px;
}

.snippet-img {
	width: 100%;
	/*height: 100%;*/
	/*
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  */
	padding-left: 10px;
	padding-right: 10px;
}

.snippet-img-exp-page {
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 1.5rem;
}

.webinar-title a {
	display: inline-block;
}

.mentor-title {
	padding-left: 15px;
}

.webinar-home-title {
	padding-left: 15px;
	margin-bottom: 35px;
}

.clear-class {
	clear: both;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.attend-now-btn {
	color: #fff;
	border: none;
	background-color: #000598;
	padding: 5px 20px 5px 20px;
	border-radius: 10px;
	font-weight: 700;
	border: none !important;
	outline: none !important;
}

.attend-now-btn-webinar {
	font-size: 1rem;
	text-align: center;
	padding: 0.5rem 1.5rem;
	border-radius: 10px;
	font-weight: 700;
	color: white;
	border: none !important;
	outline: none !important;
}

.join-btn-webinar-div {
	padding-left: 10px;
	padding-right: 10px;
}

.webinar-container {
	padding-top: 0px;
	padding-bottom: 50px;
}

.webinar-info-container {
	padding: 0 0.8rem;
	margin: 1.5rem 0;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}

.webinar-banner-img-sm {
	display: none;
}

@media screen and (max-width: 768px) {
	.webinar-basic-info-container-webinar {
		display: none !important;
	}

	.webinar-banner-img-sm {
		display: block;
		width: 100%;
	}
}

.experience-tab-title {
	color: var(--fontColor-primary);
	font-size: larger;
	font-weight: bolder;
	background-color: var(--background_secondary);
}

.webinar-content {
	white-space: pre-line;
	word-wrap: break-word;
	padding-bottom: 1rem;
	/* border-bottom: 1px solid var(--background_tertiary); */
	border-bottom: 1px solid #8b8e915d;
}

.webinar-content a {
	color: inherit;
}

.loadMoreContent {
	height: auto;
}

.loadMoreContent-hidden {
	max-height: 150px;
	overflow: hidden;
	position: relative;
}

.loadMoreContent-hidden::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0px;
	right: 0px;
	box-shadow: -11px -50px 25px -26px var(--background_primary) inset;
}

.club-loadMoreContent {
	height: auto;
}

.club-loadMoreContent-hidden {
	max-height: 150px;
	overflow: hidden;
	position: relative;
}

.club-loadMoreContent-hidden::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0px;
	right: 0px;
	box-shadow: -11px -50px 25px -26px var(--background_primary) inset;
}

.showMoreLess-btn {
	cursor: pointer;
	letter-spacing: 2px;
}

.webinar-info-container h1 {
	font-size: 32px;
	font-weight: 600;
}

.webinar-info-container h2 {
	font-size: 24px;
	font-weight: 600;
}

.webinar-icon {
	width: 25px;
	margin-right: 20px;
}

.tick-icon {
	width: 20px;
	margin-right: 15px;
	font-size: larger;
}

.other-webinar-info {
	/* background: var(--background_secondary);
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16); */
	background-color: var(--background_primary);
	box-shadow: 0px 0px 3px var(--fontColor-secondary);
	border-radius: 10px;
	padding: 20px 10px 10px 10px;
}

/*
.other-webinar-info p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
}
*/
.webinar-full-content {
	/*margin-top: 30px;*/
	margin-left: 0px;
	margin-right: 0px;
	font-family: "Gopher-Regular";
	font-size: 1 rem;
	color: var(--fontColor-primary);
}

.thing-to-do {
	position: relative;
	margin-bottom: 5px;
	display: flex;
	gap: 10px;
}

.thing-to-do span {
	/*position: absolute;*/
	bottom: -1px;
}

.webinar-full-content h3 {
	padding-top: 20px;
	color: var(--fontColor-primary);
}

.mentor-container {
	padding: 0 clamp(1rem, 5vw, 5rem);
}

.mentor-background {
	width: 100%;
	border-radius: 5vw;
}

.mentor-title-container {
	margin-top: -8vw;
	padding: 0 5rem;
	align-items: flex-end;
}

.mentor-image {
	--dimension: clamp(125px, 15vw, 250px);
	/* height: var(--dimension); */
	width: var(--dimension);
	border-radius: 50%;
	border: 2px solid #fff;
}

.mentor-followers {
	padding: 1rem;
	background-color: var(--background_secondary);
	border-radius: 40px;
	letter-spacing: 1px;
}

.mentor-followers-count {
	font-weight: 600;
	color: var(--border);
}

.mentor-message-btn {
	padding: 0.75rem 2rem;
	border-radius: 10px;
	font-weight: 600;
	letter-spacing: 2px;
	color: white;
}

.mentor-full-content {
	font-size: 16px;
}

.mentor-full-content h3 {
	font-size: 24px;
	font-weight: 600;
}

.mentor-full-content h2 {
	font-size: 26px;
	font-weight: 600;
}

.mentor-intro p {
	font-size: 1.25rem;
	color: var(--fontColor-primary);
}

.mentor-about {
	padding-top: 40px;
}

.mentor-about div {
	white-space: pre-line;
}

.mentor-webinar {
	padding-top: 50px;
}

/* Login Page */

.login-page {
	height: 100vh;
}

.login-page-popup {
	border-radius: 10px;
}

.login-popup-cross-icon {
	position: absolute;
	right: 15px;
	top: 15px;
	height: 25px;
	cursor: pointer;
	color: var(--fontColor-primary);
}

.login-box {
	padding: 8vw;
}

.login-box-popup {
	padding: 25px 40px 0px 40px;
	text-align: left;
}

.login-box-popup h1 {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 24px;
	color: #222222;
}

.login-box-popup h2 {
	font-family: "Gopher-Regular";
	font-size: 18px;
	color: #717171;
}

.login-box-popup h3 {
	font-size: 18px;
	font-weight: 600;
	margin-top: 35px;
}

.login-box-popup h1 {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 24px;
	color: #222222;
}

.login-box h2 {
	font-family: "Gopher-Regular";
	font-size: 18px;
	color: #717171;
}

.login-box h3 {
	font-size: 18px;
	font-weight: 600;
	margin-top: 35px;
}

.login-detail-box h2 {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 20px;
	margin-top: 20px;
	color: #222222;
	text-align: justify;
}

.mobile-login {
	display: block;
	width: 100%;
	border: 1px solid #c6c6c6;
	box-sizing: border-box;
	border-radius: 6px;
	height: 50px;
	padding-left: 10px;
}

.login-btn {
	width: 100%;
	/* height: 40px; */
	font-size: 14px;
	margin-top: 20px;
	border-radius: 10px;
	font-family: "Gopher-Regular";
	font-weight: 700;
	font-size: 20px;
	padding: 14px 0;
}

.login-detail-box {
	padding-left: 0px;
	text-align: center;
}

.otp-box {
	width: 100%;
}

.otp-box input {
	border: 1px solid #c6c6c6;
	box-sizing: border-box;
	border-radius: 6px;
	width: 50px !important;
	height: 50px;
	margin-right: 10px;
}

.otp-box span {
	display: none;
}

.otp-timer-div, .resend-otp-tex {
	font-size: 0.85rem;
	margin: 20px 0 10px 0;
	text-align: left;
}

@media only screen and (max-width:500px) {
	.otp-box {
		display: flex;
		justify-content: center;
		margin: 20px 5px
	}

	.mentor-container {
		padding: 0;
	}

	.mentor-intro, .mentor-about {
		padding-left: 10px;
		padding-right: 10px;
	}

}

.resend-otp-text {
	font-size: 1rem;
	margin-top: 8px;
	color: var(--border);
	font-weight: bold;
	cursor: pointer;
	text-align: left;
}

.partitioned-input {
	width: 2em !important;
}

.reg-input {
	display: block;
	width: 100%;
	border: none;
	border-bottom: 1px solid #000;
	margin-top: 30px;
	margin-bottom: 30px;
}

/* .edit-input-div {
	margin-top: 50px;
} */

.edit-input-container>div {
	margin: 2rem 0;
}

.edit-input-container>button {
	margin-bottom: 2rem;
}

.edit-profile-container {
	/* background-color: var(--background_primary); */
	color: var(--fontColor-primary);
	padding: 10px 30px;
}

.edit-profile-container-popup {
	padding: 10px 10px 10px 10px;
}

.edit-profile-container-popup h1 {
	margin-left: 150px;
}

.edit-profile-label {
	height: 35px;
	text-align: right;
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 22px;
}

.edit-first {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.edit-profile-photo {
	position: relative;
	width: fit-content;
	cursor: pointer;
}

.edit-profile-photo-cross {
	align-items: center;
	display: none;
	justify-content: center;
	border-radius: 0.5rem;
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

.edit-profile-photo-cross img {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	cursor: pointer;
}

.edit-profile-photo:hover .edit-profile-photo-cross {
	display: flex;
}

.edit-profile-photo-label {
	height: 155px !important;
}

.profile-popup-header {
	line-height: 40px;
	color: var(--fontColor-primary);
	font-size: 1.75rem;
	padding-left: 20px;
}

.edit-profile-btn {
	border: none;
	color: #fff;
	border-radius: 10px;
	font-size: 16px;
}

.edit-profile-btn-popup {
	display: block;
	padding: 10px 35px 10px 35px;
	border: none;
	color: #fff;
	border-radius: 10px;
	background-color: var(--background_gradient);
	font-size: 16px;

	margin: 0 auto;
	margin-top: 20px;
}

.mob-change-div {
	height: 27px;
	font-size: 16px;
	font-weight: 600;
}

.change-mob {
	font-size: 15px;
	font-weight: 500;
	text-decoration: underline;
	margin-left: 10px;
	cursor: pointer;
}

.edit-profile-img {
	--dimension: clamp(100px, 15vw, 175px);
	width: var(--dimension);
	height: var(--dimension);
	border-radius: 50%;
}

.profile-file-input {
	display: block;
	width: 100%;
	height: 35px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.profile-input {
	display: block;
	width: 100%;
	height: 35px;
	border-radius: 6px;
	border: solid 0.5px var(--border);
	margin-top: 30px;
	margin-bottom: 30px;
	padding-left: 10px;
}

.profile-input option {
	background-color: var(--background_secondary);
}

.profile-input-popup {
	/*width: 100%;*/
	height: 35px;
	width: 100px;
	/*border-radius: 6px;
  border: solid 0.5px #979797;*/
	margin-top: 10px;
	margin-left: 5px;
	margin-bottom: 10px;
	padding-left: 5px;

	outline: 0;
	border-width: 0 0 1px;
	border-bottom-style: dotted;
	border-color: var(--border);
}

.width-hundred {
	width: 100px !important;
}

.profile-input-select-pkg-width {
	display: inline-block;
	width: 250px;
}

.profile-input-select-pkg {
	border-radius: 6px;
	border: solid 0.5px #979797;
}

.profile-input-select-pkg div {
	border: none;
}

.class-label {
	padding-right: 0px;
	padding-left: 0px;
}

.class-section {
	padding-left: 0px;
	padding-right: 0px;
}

.class-entry {
	width: 30%;
}

.section-entry {
	width: 65%;
	border: none;
	border-bottom: 1px solid #000;
	display: inline-block;
}

.webinar-iframe-container {
	overflow: hidden;
	/* 16:9 aspect ratio */
	padding-top: 56.25%;
	position: relative;
}

.webinar-iframe {
	border: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.edvolve-full-logo {
	height: 55px;
}

/* Some responsive conditions */

@media only screen and (max-width: 700px) {
	.snippet-img-exp-page {
		width: 85vw !important;
		margin-left: -11px;
	}

	.react-multi-carousel-track {
		height: auto !important;
		width: 100% !important;
	}

	.edvolve-full-logo {
		display: block;
		height: 45px !important;
	}

	.login-btn {
		width: 100%;
	}

	.edit-profile-container {
		padding: 10px 30px 10px 30px;
	}

	.mobile-label-padding {
		padding-left: 0px;
	}

	.edit-profile-label {
		height: 35px;
		text-align: right;
		margin-top: 32px;
		margin-bottom: 30px;
		font-size: 14px;
	}

	.margin-top-extra {
		margin-top: 45px;
	}

	.webinar-container {
		padding-top: 10px;
	}

	.webinar-snippet {
		padding-right: 0px;
	}

	.webinar-snippet p {
		margin: 0 0 5px;
	}

	.webinar-snippet h3 {
		font-size: 20px !important;
		font-weight: 500 !important;
	}

	.snippet-image-mobile-webinar {
		height: auto;
		width: 100%;
	}

	.thing-to-do span {
		position: relative;
	}

	.mentor-title-container {
		margin-top: -10vw;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.mentor-image {
		border-radius: 50%;
	}

	.mentor-full-content {
		padding: 0 10px;
	}

	.dashboard-body {
		padding: 30px 0px 30px 0px !important;
	}

	.no-mob-padding {
		padding-left: 0px;
		padding-right: 0px;
	}

	.react-multi-carousel-track {
		width: 100%;
	}

	.snippet-date-dashboard {
		font-size: 16px !important;
	}

	.snippet-dashboard-text {
		font-size: 14px !important;
	}

	.snippet-with-dashboard-text {
		font-size: 14px !important;
	}

	.experience-count-dashboard-card {
		padding: 10px 5px 20px 5px !important;
	}

	.experience-count-dashboard-card span {
		text-align: center !important;
	}

	.item-container {
		min-width: 99vw !important;
	}

	.mentor-worlds {
		padding: 0 2px 0 10px;
	}

	.edit-input-container>div {
		margin: 0;
	}

	.edit-first {
		margin-top: 1rem !important;
	}

	.edit-2 {
		margin: 1rem 0 !important;
	}

	.edit-profile-btn {
		margin-top: 1rem !important;
	}
}

.qna-container {
	padding-left: 0px;
	padding-right: 0px;
}

.question-answer-div {
	padding-top: 20px;
	border-bottom: 1px solid #e4e4e4;
	padding-bottom: 10px;
}

.single-question {
	font-size: 16px !important;
}

.webinar-q-title-div {
	padding-left: 0px;
	padding-right: 0px;
}

.single-answer {
	font-size: 16px !important;
	font-weight: 400 !important;
}

.ask-q-btn {
	padding: 15px 11px 14px 10px;
	background-color: #8684cf;
	color: #fff;
	border: none;
	border-radius: 10px;
	font-size: 14px;
	margin-top: 20px;
}

.popup-q-title {
	background-color: #eaeaea;
	padding-top: 20px;
	padding-bottom: 20px;
	font-weight: 600;
	font-size: 22px;
	padding-left: 20px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.swal-qna .swal-content {
	padding: 0px !important;
	margin-top: 0px !important;
}

.swal-qna {
	border-radius: 10px !important;
	background-color: var(--background_secondary);
	overflow: hidden;
}

.swal-login .swal-content {
	padding: 0px !important;
	margin-top: 0px !important;
}

.swal-login {
	border-radius: 5px !important;
	background-color: transparent !important;
}

.swal-payment-conf .swal-content {
	padding: 0px !important;
	margin-top: 0px !important;
}

.swal-payment-conf2 .swal-content {
	padding: 0px !important;
	margin: 0px !important;
}

.swal-content:last-child {
	margin-bottom: 0 !important;
}

.swal-payment-conf {
	border-radius: 16px !important;
	width: 650px;
	background-color: var(--background_secondary);
	color: var(--fontColor-primary);
	overflow: hidden;
}

.swal-payment-conf2 {
	border-radius: 16px !important;
	width: 650px;
	background-color: var(--background_primary);
	color: var(--fontColor-primary);
	overflow: hidden;
}

.swal-payment-conf img {
	width: 100%;
	height: 250px;
	object-fit: cover;
}

.swal-payment-conf2 img {
	width: 100%;
	height: 250px;
	object-fit: cover;
}

.swal-payment-conf p {
	margin: 0;
}

.swal-payment-conf2 p {
	margin: 0;
}

.qna-popup {
	text-align: left !important;
}

.qna-popup-content textarea {
	margin-top: 30px;
	width: 100%;
}

.qna-popup p {
	font-style: italic;
	font-size: 14px;
}

.qna-popup-content {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 60px;
}

.qna-popup-btn-div {
	float: right;
}

.post-q-btn {
	background-color: #f5a331;
	padding: 13px 24px 12px 23px;
	border: none;
	border: 1px solid #da7f00;
	border-radius: 10px;
	font-size: 14px;
	margin-left: 10px;
}

.cancel-q-btn {
	background-color: #fff;
	padding: 13px 24px 12px 23px;
	border: none;
	border: 1px solid #969696;
	border-radius: 10px;
	font-size: 14px;
}

.qna-pagination-parent {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}

.qna-pagination {
	margin: 0 auto;
}

.selected-qna-pagination {
	border: 1px solid #f5a331 !important;
	color: #f5a331;
}

.greyed-qna-pagination {
	background-color: #d8d8d8;
	cursor: pointer;
}

.pagination-single-qna {
	padding: 10px 15px 10px 15px;
	display: inline-block;
	border: 1px solid #969696;
	margin-right: 6px;
	border-radius: 5px;
	font-weight: 600;
}

.see-more-qna-btn {
	padding: 10px 15px 10px 15px;
	background-color: #d8d8d8;
	border: none;
	border-radius: 10px;
	margin-top: 10px;
	color: #000;
}

.qna-page-container h1 {
	font-size: 32px;
	font-weight: 600;
}

.qna-page-container h2 {
	font-size: 24px;
	font-weight: 400;
}

.qna-page-qnas {
	padding-bottom: 40px;
}

.exp-date-box,
.coupon-code-box {
	height: 465px;
	/* background-color: var(--background_secondary);
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16); */
	background-color: var(--background_primary);
	box-shadow: 0px 0px 3px var(--fontColor-secondary);
	border-radius: 30px;
	overflow-y: auto;
	scrollbar-width: thin;
}

.exp-date-box-webinar {
	height: 250px;
	background-color: var(--background_secondary);
	border-radius: 30px;
	margin-left: 15px;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
}

.date-exp-container-wrapper {
	background-color: var(--background_secondary);
	border-top: 2px solid var(--border);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}

.date-exp-container {
	padding: 10px 20px;
}

.date-exp-container-second {
	padding: 0px 20px 10px 20px;
}

.date-exp-container-second-webinar {
	position: fixed;
	height: 85vh;
	bottom: 0;
	left: 0;
	z-index: -6;
	width: 98%;
	margin: 0 1%;
	padding: 0px 20px 10px 20px;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	border-top: 1px solid var(--border);
	background-color: var(--background_primary);
}

.price-experience {
	font-size: 1rem;
	color: var(--fontColor-secondary);
}

.price-experience>span.discounted {
	font-size: 1.5rem;
	font-weight: 800;
	color: #56ccf2;
	padding-left: 0.75rem;
	padding-right: 0.5rem;
}

.price-experience>span.original {
	font-size: 1rem;
	color: #56ccf2;
	text-decoration: line-through;
	text-decoration-thickness: 1.5px;
}

.price-experience>span.per_class {
	font-size: 1rem;
}

.price-experience>p {
	font-size: 1.1rem;
	margin: 0;
}

@media (min-width: 768px) {
	.price-experience>span.original {
		display: block;
	}

	.price-experience>span.discounted {
		padding-left: 0;
	}

}

.time-schedule-exp {
	font-size: 12px;
	font-weight: 800;
	/*padding-top: 5px;*/
	padding-right: 0px;
	padding-left: 10px;
}

.time-schedule-exp p {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 15px;
	color: var(--fontColor-primary);
	margin-bottom: 0px;
}

.time-range-box {
	color: var(--fontColor-secondary) !important;
	font-weight: normal !important;
}

.single-schedule-exp {
	border-bottom: 1px solid #dddddd;
	padding-bottom: 20px;
	padding-top: 20px;
}

.single-schedule-webinar {
	padding-bottom: 20px;
	padding-top: 20px;
}

.attend-now-btn-schedule {
	color: #fff;
	border: none;
	background-color: #1d9929;
	padding: 6px 17px 6px 17px;
	border-radius: 10px;
	font-weight: 600;
	font-size: 12px;
	margin-top: 5px;
}

.attend-now-btn-schedule-grey {
	color: #fff;
	border: none;
	background-color: #bdbdbd;
	padding: 6px 17px 6px 17px;
	border-radius: 8px;
	font-weight: 600;
	font-size: 12px;
	margin-top: 5px;
}

.attend-now-btn-course {
	color: #fff;
	border: none;
	background-color: #1d9929;
	padding: 6px 17px 6px 17px;
	border-radius: 10px;
	font-weight: 600;
	font-size: 12px;
	margin-top: 10px;
}

.attend-now-btn-course-grey {
	color: #fff;
	border: none;
	background-color: #bdbdbd;
	padding: 6px 17px 6px 17px;
	border-radius: 8px;
	font-weight: 600;
	font-size: 12px;
	margin-top: 10px;
}

.exp-snippet-mentor {
	padding-left: 15px;
	padding-right: 40px;
	margin-top: 20px;
}

.experience-title {
	padding-left: 30px;
	margin-bottom: 25px;
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 1.5rem;
	color: var(--fontColor-primary);
}

.exp-with-snippet {
	font-family: "Gopher-Regular";
	font-size: 14px;
	color: #222222;
}

.live-from-snippet {
	font-family: "Gopher-Regular";
	font-size: 0.8rem;
	color: var(--fontColor-secondary);
}

.payment-img {
	/*height: 305px;*/
	width: 100%;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

.payment-img-conf {
	width: 100%;
	margin: 1rem auto;
	border-radius: 0.5rem;
}

.payment-popup-content {
	padding: 0px;
}

.payment-popup-content-batch {
	padding-top: 40px;
}

.payment-popup-content-conf {
	padding: 10px 30px 10px 30px;
}

.payment-popup {
	margin-top: -15px;
	border-radius: 10px !important;
}

.payment-popup-conf {
	margin-top: -20px;
	border-radius: 5px;
}

.payment-popup p {
	text-align: left;
}

.payment-popup-text-content {
	background-color: var(--background_secondary);
	padding: 20px 30px;
}

.payment-popup-text-content h4,
.payment-popup-text-content p {
	color: var(--fontColor-primary) !important;
}

.payment-popup-text-content .muted-text {
	color: var(--fontColor-secondary) !important;
}

.payment-popup-text-content h3 {
	font-family: "Gopher-Regular";
	font-weight: 600;
	/* font-size: 16px; */
	color: var(--fontColor-primary);
	text-align: left;
}

.coupon-popup {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.coupon-popup-content div {
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
}

.coupon-input-box {
	/* width: 100%; */
	width: 150px !important;
	margin-right: 15px;
	height: 35px;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 5px;
	outline: 0;
	border-width: 0 0 1px;
	border-bottom-style: dotted;
	border-color: #979797;
}

.coupon-popup-content div p {
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
	margin-bottom: 0px;
	margin-top: 0px;
}

.margin-bottom-five {
	margin-bottom: 5px !important;
}

.bold-text {
	font-weight: 600;
}

.payment-popup-text-content p {
	font-family: "Gopher-Regular";
	font-size: 14px;
	margin-bottom: 0px;
	color: var(--fontColor-primary);
}

.third-section-payment {
	text-align: left;
}

.fourth-section-payment {
	text-align: left;
	margin-bottom: 20px;
}

.coupon-section-payment {
	text-align: left;
	margin-bottom: 20px;
	color: #1c9929;
	font-weight: 600;
	font-size: 13px;
	cursor: pointer;
}

.third-section-payment h4 {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #222222;
	margin-top: 0px;
	margin-bottom: 15px;
}

.third-section-payment p {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-align: right;
	color: #717171;
	margin-top: 0px;
	margin-bottom: 8px;
}

.fourth-section-payment h4 {
	font-family: "Gopher-Regular";
	font-weight: bold;
	font-size: 20px;
	line-height: 25px;
	color: #222222;
	margin-top: 0px;
	margin-bottom: 0px;
}

.fourth-section-payment p {
	font-family: "Gopher-Regular";
	font-weight: bold;
	font-size: 20px;
	line-height: 25px;
	text-align: right;
	color: #222222;
	margin-top: 0px;
	margin-bottom: 0px;
}

.payment-participating-text {
	font-size: 18.5px;
	font-weight: bold;
	color: #4b4b4b;
}

.payment-participating-text-conf {
	font-size: 18.5px;
	font-weight: bold;
	color: var(--fontColor-primary);
	margin-top: 45px;
}

.exp-title-payment {
	font-size: 17px;
	font-weight: 600;
	margin-bottom: 0px;
}

.exp-title-payment-conf {
	color: var(--fontColor-primary);
	font-size: 1.5rem;
	font-weight: 500;
	margin-bottom: 0px;
	text-align: left;
}

.date-payment-popup {
	font-size: 14px;
	font-weight: 600;
	margin-top: 20px;
}

.date-payment-popup-conf {
	font-size: 14px;
	font-weight: 600;
	margin-top: 20px;
	text-align: left;
	color: var(--fontColor-secondary);
}

.total-payment-text {
	font-size: 14px;
	font-weight: bold;
}

.cancellation-payment-popup {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 12px;
	color: #222222;
	margin-bottom: 0px;
}

.cancellation-text-popup {
	font-family: "Gopher-Regular" !important;
	font-size: 12px !important;
	color: #717171 !important;
}

.acceptance-payment-popup {
	font-size: 12px;
	font-weight: 600;
}

.t-c-accept {
	margin-top: 10px;
	margin-bottom: 20px;
}

.first-section-payment {
	padding-bottom: 0px;
	padding-top: 0px;
}

.exp-language-payment {
	font-size: 12px;
	color: #4b4b4b;
	margin-top: 12px;
}

.exp-language-payment-conf {
	font-size: 12px;
	color: var(--fontColor-secondary);
	margin-top: 12px;
	text-align: left;
}

.payment-popup-btn-div {
	padding-bottom: 10px;
}

.pay-btn {
	background-color: #1d9929;
	padding: 7px 25px 7px 25px;
	border: none;
	border-radius: 10px;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	font-family: "Gopher-Regular";
	z-index: 9999;
}

.link-payment-popup {
	font-size: 13px;
	font-weight: 600;
}

.link-payment-popup-conf {
	font-size: 13px;
	font-weight: 600;
	text-align: left;
	margin-top: 20px;
	color: var(--fontColor-secondary);
}

/* Dashboard */

.dashboard-body {
	/* background-color: var(--background_primary); */
	color: var(--fontColor-primary);
	height: 100%;
	margin-left: 0px;
	margin-right: 0px;
	padding: 30px 100px 30px 100px;
}

.experience-box-card {
	padding-left: 10px;
	padding-right: 10px;
}

.experience-snippet-dashboard-parent {
	padding-left: 10px;
	padding-right: 10px;
}

.carousel-parent-dashboard {
	margin-left: -10px;
	margin-right: -10px;
}

.experience-count-dashboard-card {
	background: var(--background_secondary);
	border: 0.4px solid var(--border);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 10px 15px 20px 15px;
}

.experience-count-dashboard-card span {
	height: 44px;
	color: var(--fontColor-secondary);
	font-family: "Gopher-Regular";
	display: block;
	font-weight: normal;
	font-size: 16px;
}

.experience-count-dashboard {
	font-family: "Gopher-Regular" !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 34px !important;
	/* identical to box height */
	color: var(--fontColor-secondary) !important;
	margin-bottom: 10px;
}

.student-welcome-dashboard {
	padding-left: 30px;
}

.student-welcome-dashboard h2 {
	font-family: "Gopher-Regular";
	font-weight: bold;
	font-size: 30px !important;
	color: var(--fontColor-primary);
	/*padding-left: 20px;*/
}

.student-welcome-dashboard p {
	font-family: "Gopher-Regular";
	font-size: 16px;
	color: var(--fontColor-secondary);
}

@media only screen and (max-width:768px) {
	.student-welcome-dashboard {
		padding-left: 15px;
	}
}

.left-stat-div {
	background: var(--background_secondary);
	/* background: var(--background_primary); */
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 20px;
	border-radius: 30px;
}

.left-stat-div2 {
	/* background: var(--background_secondary); */
	background: var(--background_primary);
	height: 550px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 20px;
	border-radius: 30px;
}

.left-stat-div h4 {
	font-family: "Gopher-Regular";
	font-weight: bold;
	font-size: 18px;
	color: var(--fontColor-primary);
	padding-left: 10px;
}

.left-stat-div2 h4 {
	font-family: "Gopher-Regular";
	font-weight: bold;
	font-size: 18px;
	color: var(--fontColor-primary);
	padding-left: 10px;
	margin-bottom: -100px;
}

@media screen and (max-width: 1270px) {
	.left-stat-div2 h4 {
		margin-bottom: 0px;
	}
}


.left-stat-div p {
	font-size: 16px;
	text-align: left;
	color: #4f4f4f;
	/*padding-left: 20px;*/
}

.exposure-report-div {
	background-color: var(--background_secondary);
	padding: 20px 30px 20px 30px;
	margin-top: 26px;
	height: 400px;
	border-radius: 30px;
}

.indiv-exposure-card {
	border: 0.4px solid #c6c6c6;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 15px;
}

.indiv-exposure-card h5 {
	font-family: "Gopher-Regular";
	font-weight: bold;
	font-size: 16px;
	color: var(--fontColor-primary);
}

.indiv-exposure-card p {
	font-family: "Gopher-Regular";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: var(--fontColor-secondary);
}

.exposure-report-div h2 {
	font-size: 18px;
	font-weight: 600;
	text-align: left;
	color: var(--fontColor-primary);
	font-family: "Gopher-Regular";
	margin-left: -15px;
}

.exposure-report-div table {
	border-collapse: collapse;
	width: 100%;
	margin-left: 15px;
}

.exposure-report-div tbody {
	height: 400px;
	/* Just for the demo          */
	overflow-y: auto;
	/* Trigger vertical scroll    */
}

.exposure-report-div td,
th {
	text-align: left;
	padding: 8px;
}

.exposure-report-div th {
	color: #9c9999;
}

.subject-td {
	color: #000;
	font-size: 16px;
	font-weight: 600;
}

.mentor-td {
	color: #7c77f4;
	font-size: 16px;
	font-weight: 600;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.sno-box-dashboard-table {
	padding: 4px 7px 4px 7px;
	border: 2px solid #7c77f4;
	border-radius: 4px;
	font-size: 12px;
}

.snippet-dashboard-img {
	/*height: 125px;*/
	width: 100%;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
}

.snippet-dashboard-text {
	margin-top: 0px;
	color: var(--fontColor-primary);
	font-size: 1rem;
	font-family: "Gopher-Regular";
	overflow: hidden;
}

.snippet-dashboard-class-name {
	font-weight: bold;
}

.snippet-with-dashboard-text {
	color: var(--fontColor-secondary);
	font-size: 12px;
}

.snippet-date-dashboard {
	font-family: "Gopher-Regular";
	font-style: normal;
	font-weight: 600;
	font-size: 0.75rem;
	color: var(--fontColor-secondary);
	margin-bottom: 5px;
}

.snippet-dashboard-last {
	position: relative;
	margin-top: 13px;
}

.snippet-duration-dashboard {
	display: block;
	float: left;
	margin-left: 5px;
	font-size: 11px;
	color: #606060;
	position: absolute;
	top: 10px;
}

.snippet-dashboard-attend {
	color: #fff;
	background-color: #1d9929;
	border-radius: 10px;
	border: none;
	padding: 0.5rem 1.5rem;
	cursor: pointer;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 1px;
}

.experience-snippet-dashboard {
	position: relative;
	background-color: var(--background_primary);
	border-radius: 30px;
	overflow: hidden;
}

.exp-snippet-dashboard-texts {
	padding: 10px 20px;
}

.upcoming-exp-dashboard-div {
	background-color: var(--background_secondary);
	color: var(--fontColor-primary);
	padding-bottom: 20px;
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 30px;
	margin-bottom: 30px;
}

.upcoming-exp-dashboard-div h2 {
	font-size: 18px;
	font-weight: 600;
	text-align: left;
	color: #333333;
	padding-left: 20px;
	padding-top: 15px;
	font-family: "Gopher-Regular";
	color: var(--fontColor-primary);
}

.container {
	width: 100% !important;
	margin-top: 20px;
}

.mentor-det-dashboard-table {
	display: inline-block;
	padding-right: 50px;
	padding-left: 0px;
}

.profile-pic-dashboard-table {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	box-sizing: border-box;
	border: 1px solid #2a2a2a;
	margin-right: 5px;
	margin-bottom: 10px;
}

.mentor-name-dasbhoard-table {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 14px;
	color: var(--fontColor-primary);
}

.mentor-city-dasbhoard-table {
	text-align: center;
	margin-top: 0px;
	font-style: italic;
	color: #e8e300;
}

.raise-issue-div {
	/*margin-top: 30px;*/
	text-decoration: underline;
	padding-left: 30px;
	margin-bottom: 10px;
}

.raise-issue-div a {
	color: #646464;
}

.container-exp-page {
	height: auto;
	width: 100% !important;
}

.slider-menu {
	height: auto;
	width: 100% !important;
}

.react-multi-carousel-track {
	height: 100%;
}

.react-multi-carousel-list {
	border-radius: 1.5rem;
}

.course-class-card {
	border-radius: 1.25rem;
	margin-top: 1.5rem;
	padding: 1rem 1.25rem;
	background-color: var(--background_secondary);
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
}

.course-classes-title {
	font-size: 1.1rem;
	font-weight: 600;
	margin-right: 0.25rem;
}

.course-classes-description {
	font-size: 1rem;
}

.course-classes-popup {
	font-size: 16px;
	padding: 20px;
	border-top: solid 0.1px var(--border);
}

.batch-schedule-seperator {
	margin-top: 0px;
}

.batch-starts-from {
	font-size: 16px;
	font-weight: 600;
	color: #676767;
	margin-top: 20px;
}

.time-schedule-course {
	font-size: 14px;
	font-weight: 800;
	color: #666666;
	padding-top: 5px;
	padding-right: 0px;
	padding-left: 10px;
}

.time-schedule-course p {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 15px;
	color: #222222;
	margin-bottom: 0px;
}

.start-time-courses {
	display: block;
}

.full-schedule-view {
	display: block;
	opacity: 0.45;
	font-size: 11.5px;
	font-weight: 600;
	text-decoration: underline;
	cursor: pointer;
}

.batch-start-popup-text {
	font-size: 18.5px;
	font-weight: bold;
	margin-bottom: 20px;
	padding-left: 10px;
}

.course-class-content {
	border-bottom: 1px solid #c9c9c9;
	padding-bottom: 30px;
}

.extra-top-margin {
	margin-top: 20px;
}

.profile-popup-main {
	background-color: var(--background_secondary);
	text-align: left;
	padding: 2rem 1rem 1.25rem;
	border-radius: 10px;
}

.profile-input-email {
	display: block;
	width: 100%;
	height: 35px;
	border-radius: 6px;
	border: solid 0.5px #979797;
	margin-top: 30px;
	margin-bottom: 5px;
	padding-left: 10px;
}

.profile-input-email-popup {
	/*width: 100%;*/
	width: 150px;
	height: 35px;
	/*border-radius: 6px;
    border: solid 0.5px #979797;*/
	margin-top: 30px;
	margin-bottom: 5px;
	padding-left: 10px;

	outline: 0;
	border-width: 0 0 1px;
	border-bottom-style: dotted;
	border-color: #979797;
}

.profile-input-dob {
	display: block;
	width: 100%;
	height: 35px;
	border-radius: 6px;
	border: solid 0.5px #979797;
	margin-top: 10px;
	margin-bottom: 30px;
	padding-left: 10px;
}

.profile-input-popup-dob {
	/*width: 100%;*/
	width: 200px;
	margin-left: 5px;
	height: 35px;
	border-radius: 6px;
	border: solid 0.5px #979797;
	margin-top: 10px;
	margin-bottom: 30px;
	padding-left: 10px;
}

.past-recording-home {
	margin: 0 auto;
	/* width: 90%; */
	padding: 1.5rem;
	/* background-color: var(--background_primary); */
	min-height: 87vh;
}

.side-header-div {
	padding: 20px;
	background-color: var(--background_secondary);
	border-radius: 10px;
}

.side-header-div>a {
	text-decoration: none;
}

.title-side-header {
	font-size: 15px;
	font-weight: bold;
	text-align: left;
	color: #222222;
	margin-bottom: 15px;
}

.side-header-div div {
	padding: 7.5px 58px 8px 10px;
	color: var(--fontColor-primary);
}

.selected-side-header {
	border-radius: 5px;
	color: white !important;
	background-color: var(--border);
	font-weight: 600;
	text-align: left;
}

.whole-past-recording {
	background-color: var(--background_secondary);
	border-radius: 30px;
	padding: 1.5rem 2rem;
}

.whole-past-recording h2 {
	font-size: 16px;
	font-weight: 600;
	text-align: left;
	color: var(--fontColor-primary);
	/*margin-left: -15px;*/
}

.snippet-content-past-recording {
	padding: 3px;
	margin-top: 25px;
}

.snippet-content-past-recording h3 {
	font-size: 12px;
	text-align: left;
	color: #222222;
}

.single-snippet-recording {
	border-radius: 15px;
	border: solid 0.3px #414141;
	margin-top: 20px;
	background-color: var(--background_primary);
	padding-bottom: 20px;
}

.snippet-image-past-recording {
	height: 200px;
	padding-left: 0px;
}

.snippet-mentor-past-recording {
	font-size: 0.8rem;
	text-align: left;
	color: var(--fontColor-secondary);
}

.snippet-completed-past-recording {
	font-size: 11px;
	text-align: left;
	color: var(--fontColor-secondary);
}

.replay-btn-past-recording {
	padding: 0.5rem 1rem;
	border-radius: 10px;
	color: white;
	float: left;
}

.snippet-img-past-recording {
	/*width: 100%;*/
	max-width: 100%;
	height: 100%;
	border-radius: 15px;
	/*box-shadow: 2px 2px 0.4em #d0d0d0;*/
	margin-top: 12px;
}

.no-upcoming-past-recording {
	font-size: 11px;
	text-align: center;
	color: var(--fontColor-secondary);
	margin-top: 50px;
}

.bold-no-past-recording {
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	color: var(--fontColor-primary);
}

.no-btn-past-recording {
	padding: 5px 16px 5px 16.5px;
	border-radius: 10px;
	border: none;
	margin: 0 auto;
	color: white;
}

.advantages-container {
	background-color: var(--background_secondary);
	padding: 2rem 3rem;
	margin-top: 20px;
	margin-bottom: 35px;
	border-radius: 30px;
}

.advantages-container>h5 {
	color: var(--fontColor-primary);
	font-weight: 600;
}

.advantages-all {
	display: flex;
	margin-top: 1.5rem;
}

.advantage-single-content span {
	text-align: center;
	display: block;
	padding: 0 8px;
}

.advantage-single {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	text-align: center;
}

.advantage-single-content>h6 {
	color: var(--fontColor-primary);
	font-weight: 600;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}

.advantage-single-img {
	width: 75px;
}

.rules_container {
	padding-right: 150px;
}


@media screen and (max-width: 768px) {
	.login-box {
		padding: 15vw;
	}

	.advantages-all {
		flex-direction: column;
	}

	.advantage-single {
		margin-top: 1.75rem;
	}

	.mobile-advantages {
		display: block !important;
		width: 100%;
	}

	/* .full-detail-content h1 {
        font-size: 1.25rem !important;
    } */

	/* .full-detail-content h2 {
		font-size: 18px !important;
	} */

	.webinar-container {
		padding: 0 0 50px;
	}

	.webinar-book-box {
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: #fff;
		z-index: 100;
		width: 100% !important;
	}

	.coupon-code-box {
		display: none;
	}

	.exp-date-box-webinar {
		padding-left: 0px;
		margin-left: 0px;
		border-radius: 0px;
	}

	.date-exp-container {
		padding: 0px;
	}

	.exp-date-box-webinar {
		height: auto;
	}

	.exp-date-box {
		height: auto;
		margin-left: 0px;
		border-radius: 0px;
	}

	.pricing-booking-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 1rem;
		border-top: 2px solid var(--border);
	}

	.react-multiple-carousel__arrow {
		z-index: 50;
	}

	.date-exp-container-second {
		position: fixed;
		height: 85vh;
		bottom: 0;
		left: 0;
		z-index: -6;
		width: 100%;
		border-top-right-radius: 15px;
		border-top-left-radius: 15px;
		border-top: 1px solid var(--border);
		background-color: var(--background_secondary);
		overflow-y: scroll;
	}

	.header-div {
		height: 50px !important;
		/* border-bottom: 0.5px solid #979797; */
		/* box-shadow: 0px 6px 10px rgb(0 0 0 / 8%); */
		padding-top: 10px;
		display: flex;
		/* justify-content: center; */
		position: relative;
	}

	.live-from-snippet {
		font-size: 0.8rem;
	}

	.live-from-snippet {
		font-family: "Gopher-Regular";
		font-size: 0.8rem;
		color: var(--fontColor-secondary);
	}

	.experience-title {
		padding-left: 10px;
		margin-bottom: 25px;
		font-family: "Gopher-Regular";
		font-weight: 600;
		font-size: 1.25rem;
	}

	.mentor-full-content h3 {
		font-size: 12px;
		font-weight: 600;
	}

	.exp-snippet-mentor {
		padding-left: 5px;
		padding-right: 5px;
		margin-top: 20px;
	}

	.footer-main-div {
		padding-left: 10px;
	}

	.footer-main-div a {
		padding-left: 10px;
		color: #646464;
		/* display: block; */
	}

	.social-media-footer {
		float: none;
		padding-right: 0px;
	}

	.rules_container {
		padding-right: 0px;
		padding: 0 10px;
	}

}

.title-mob-time-box {
	font-family: "Gopher-Regular";
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: var(--fontColor-primary);
	margin-top: 10px;
	margin-bottom: 10px;
}

.margin-minus-five {
	margin-left: -5px;
	margin-right: -5px;
}

.nav-tabs {
	border: none;
}

.nav-link {
	transition: none;
}

.nav-tabs .nav-link {
	color: var(--fontColor-secondary);
}

.nav-link:hover {
	border: none;
}

.nav-tabs .nav-link.active {
	color: var(--fontColor-primary);
	/* background-color: var(--background_primary); */
	background-color: inherit;
	border: none;
	border-bottom: 2px solid var(--border);
	border-radius: 0 !important;
}

.nav-container {
	display: none;
	position: fixed;
	height: 100vh;
	width: 100%;
	pointer-events: none;
	z-index: 100;
}

.nav-container .bg {
	position: absolute;
	top: 70px;
	left: 0;
	width: 100%;
	height: calc(100% - 70px);
	visibility: hidden;
	opacity: 0;
	transition: 0.3s;
	background: #000;
}

.nav-container:focus-within .bg {
	visibility: visible;
	opacity: 0.6;
}

.nav-container * {
	visibility: visible;
}

.button {
	position: fixed;
	top: 17px;
	left: -10px;
	flex-direction: column;
	justify-content: center;
	z-index: 1;
	-webkit-appearance: none;
	border: 0;
	background: transparent;
	border-radius: 0;
	height: 70px;
	width: 30px;
	cursor: pointer;
	pointer-events: auto;
	margin-left: 25px;
	touch-action: manipulation;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-weight: 700;
}

.icon-bar {
	display: block;
	width: 100%;
	height: 3px;
	background: #aaa;
	transition: 0.3s;
}

.icon-bar+.icon-bar {
	margin-top: 5px;
}

.nav-container:focus-within .button {
	pointer-events: none;
}

.nav-container:focus-within .icon-bar:nth-of-type(1) {
	transform: translate3d(0, 8px, 0) rotate(45deg);
}

.nav-container:focus-within .icon-bar:nth-of-type(2) {
	opacity: 0;
}

.nav-container:focus-within .icon-bar:nth-of-type(3) {
	transform: translate3d(0, -8px, 0) rotate(-45deg);
}

.nav-content {
	margin-top: 40px;
	padding: 20px 0px 20px 0px;
	width: 90%;
	max-width: 300px;
	position: absolute;
	top: 0;
	left: 0;
	/*height: calc(100% - 70px);*/
	height: 100%;
	pointer-events: auto;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	transform: translateX(-100%);
	transition: transform 0.3s;
	will-change: transform;
	/*contain: paint;*/
	z-index: 100;
}

.nav-content ul {
	height: 100%;
	display: flex;
	flex-direction: column;
	list-style-type: none;
	font-family: "Gopher-Regular";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;

	color: #000000;
}

.nav-content li a {
	padding: 10px 0px;
	display: block;
	text-transform: uppercase;
	transition: color 0.1s;
	color: #000;
}

.nav-content li a:hover {
	color: var(--fontColor-primary);
}

.nav-content li:not(.small)+.small {
	margin-top: auto;
}

.small {
	display: flex;
	align-self: center;
}

.small a {
	font-size: 14px;
	font-weight: 600;
	color: #000;
}

.small a+a {
	margin-left: 15px;
}

.nav-container:focus-within .nav-content {
	transform: none;
}

.no-upcoming-dashboard {
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;
}

.no-upcoming-dashboard a {
	text-decoration: none;
}

.no-bookings-yet {
	font-family: "Gopher-Regular";
	font-size: 18px;
	text-align: center;
	color: var(--fontColor-secondary);
	margin-top: 20px;
	margin-bottom: 20px;
}

.dashboard-exposure-no-tr {
	display: block;
	width: 99.9%;
	clear: both;
}

.dashboard-exposure-no-td {
	text-align: center !important;
	display: block;
	width: 99.9%;
	clear: both;
}

.dashboard-browse-btn {
	color: white;
	border-radius: 10px;
	font-size: 12px;
	padding: 0.5rem 1.5rem;
}

.dashboard-browse-btn a {
	color: white;
}

.background-banner-listings {
	position: relative;
	padding: 0 5%;
}

.text-in-listings-banner {
	/* position: absolute; */
	top: 0;
	margin-left: 5vw;
}

.text-in-listings-banner h2 {
	font-family: "Gopher-Regular";
	font-weight: bold;
	font-size: 30px;
	color: var(--fontColor-primary);
	padding-right: 40px;
}

.background-banner-listings img {
	height: 280px;
	width: 100%;
}

.text-in-listings-banner p {
	max-width: 70%;
	font-family: "Gopher-Regular";
	font-size: 20px;
	color: var(--fontColor-primary);
}

@media only screen and (min-width: 768px) {
	.text-in-listings-banner {
		padding-top: 40px;
		padding-bottom: 20px;
		margin-left: 2vw;
	}
}

@media only screen and (max-width: 768px) {
	.share-div {
		margin-top: 10px;
	}

	.background-banner-listings {
		position: relative;
		overflow: hidden;
	}

	.background-banner-listings img {
		height: 280px;
		width: auto !important;
	}

	.rounded-img {
		border-radius: 7% !important;
	}

	.text-in-listings-banner h2 {
		padding-top: 30px;
	}

	.mentorTabContainer ul li button {
		font-size: 14px !important;
	}
}

.login-img-div {
	height: 100vh;
}

.login-img {
	height: 100%;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
}

.full-detail-content {
	padding: 0 min(3rem, 7%);
	justify-content: space-between;
	background-color: var(--background_primary);
}

.full-detail-content h1 {
	padding: 0;
	font-family: "Gopher-Regular";
	/* font-weight: bold; */
	/* font-size: clamp(1.5rem, 3vw, 2rem) !important; */
	color: var(--fontColor-primary);
	margin-bottom: 0.5rem;
	line-height: 2rem;
}

.full-detail-content h2 {
	margin-top: 10px;
	font-family: "Gopher-Regular";
	font-size: 16px;
	color: var(--fontColor-primary);
	margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
	.full-detail-content {
		padding: 0 1.5rem;
	}

	/* .full-detail-content h1 {
		padding-left: 0.75rem;
	} */
}

.by-new-design {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.by-new-design a {
	color: var(--fontColor-primary);
	text-decoration: none;
}

.by-new-design p {
	margin: 0;
}

.other-webinar-info h5 {
	font-family: TTNorms-Black;
	;
	font-size: 16px;
	color: var(--fontColor-secondary);
}

.other-webinar-info p {
	font-family: "Gopher-Regular";
	font-weight: 600;
	font-size: 15px;
	color: var(--fontColor-primary);
}

.mentor-icon-detail-page {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	display: inline-block;
	cursor: pointer;
	position: relative;
}

.mentor-big-detail-page {
	height: 55px;
	width: 55px;
	border-radius: 50%;
	display: inline-block;
	/*cursor: pointer;*/
	position: relative;
}

.hide-it {
	display: none;
}

.per-person-span {
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	color: var(--fontColor-secondary) !important;
	display: block;
}

.availability-back-icon {
	float: left;
	margin-left: -25px;
	width: 25px;
}

.availability-cross-icon {
	position: absolute;
	right: 10px;
	top: 10px;
	height: 30px;
}

.batch-classes-cross-icon {
	position: absolute;
	right: 5px;
	top: 25px;
	height: 20px;
	cursor: pointer;
}

.rounded-img {
	border-radius: 3%;
}

.share-div {
	/* border: 1px solid var(--border); */
	/* border-radius: 10px; */
	text-align: center;
	transform: scale(1.15);
	padding-bottom: 5px;
	width: 70px;
	float: right;
	cursor: pointer;
}

.share-div span {
	font-size: 14px;
	color: var(--fontColor-primary);
	margin-top: 5px;
	margin-left: 2px;
	font-weight: 600;
	text-decoration: underline;
}

.share-icon {
	display: inline-block;
	height: 15px;
}

/* Slider Menu CSS */
.menu-item {
	padding: 0 40px;
	margin: 5px 10px;
	user-select: none;
	cursor: pointer;
	border: none;
}

.menu-item-wrapper.active {
	border: 1px blue solid;
}

.menu-item.active {
	border: 1px green solid;
}

.scroll-menu-arrow {
	padding: 20px;
	cursor: pointer;
}

.mobile-scroll-menu {
	/* border-top: 1px solid #446; */
	/* background: #182330; */
	color: var(--fontColor-primary);
	height: 45px;
	padding: 10px 0.9rem;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
	display: none;
}

.scroll-menu-header {
	height: 30px;
	margin: 0 0.25rem;
	padding: 0 0.25rem;
}

.scroll-menu-header a {
	color: var(--fontColor-primary);
	font-size: 1rem;
	text-transform: none !important;
	text-decoration: none;
}

.scroll-menu-header-icon {
	margin-right: 15px;
	color: white;
}

.scroll-menu-header-underline {
	border-bottom: 2px solid var(--border);
	font-weight: 600;
}

.arrow-button {
	padding: 0.5rem 0.75rem;
	cursor: pointer;
	border-radius: 50%;
	background: var(--border);
	opacity: 0.75;
	z-index: 1001;
}

.arrow-button:hover {
	opacity: 1 !important;
}

.modal-dialog {
	display: grid;
	place-content: center;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	padding-bottom: 30px;
	/* box-shadow: var(--shadow_primary); */
}

.modal-content {
	border: none;
	background: var(--background_primary) !important;
	max-width: 600px;
	border-radius: 30px;
	box-shadow: var(--shadow_primary);
}


@media only screen and (max-width: 768px) {
	.modal-dialog {
		margin: 0;
	}
}

.modal-dialo {
	max-width: 1000px;
}

.filter-card {
	display: inline-block;
	padding: 10px 14px;
	margin: 0px 5px;
	cursor: pointer;
	letter-spacing: 1px;
}

.filter-card-active {
	background: var(--background_gradient);
	border-radius: 25%/50%;
}

.filter-card-active-big {
	background: var(--background_gradient);
	border-radius: 16%/50%;
	color: white;
}

.filter-card-active-supreme {
	background: var(--background_gradient);
	border-radius: 10%/50%;
}

.filter-text {
	padding: 8px 36px;
	font-weight: 600;
	letter-spacing: 1px;
}

.filter-error-message {
	display: inline-block;
	padding: 10px;
}

.filter-line {
	border-top: 1px solid var(--border);
}

.filter-scroll-menu-padding {
	margin-bottom: 10px;
	padding-top: 20px;
}

/* React Read More */
.read-more-button {
	font-weight: 600;
	cursor: pointer;
	font-size: 18px;
}

/* Footer CSS */

footer {
	background-color: var(--background_secondary);
	color: var(--fontColor-primary);
}

.footer-head {
	padding-top: 1.5rem;
	padding-left: 25px;
	padding-right: 25px;

}

.center {
	text-align: center;
}

.footer-logo-img {
	width: 100%;
	max-width: 250px;
}

.footer-first-section-paragraph {
	font-style: normal;
	letter-spacing: 0.1px;
	color: var(--fontColor-primary);
}

.footer-links a {
	color: var(--fontColor-primary) !important;
}

.footer-first-section-social {
	margin-left: -10px;
}

.footer-first-section-social-icon {
	height: 3.5rem;
	padding-left: 10px;
}

.footer-first-section-copyright {
	letter-spacing: 0.2px;
	padding-top: 10px;
	font-style: normal;
}

.footer-links {
	padding-top: 1.7rem;
}

.footer-links a {
	padding-bottom: 0.5rem;
	text-decoration: none;
	color: var(--fontColor-secondary);
}

.footer-links a:hover {
	text-decoration: underline;
}

.footer-padding {
	padding-bottom: 20px;
}

@media only screen and (max-width:600px) {
	footer {
		padding-bottom: 4rem
	}

	.footer-links {
		padding-top: 0.5rem;
		margin-bottom: 20px;
	}

	.footer-first-section-social {
		justify-content: space-between;
		display: flex;
	}

	.footer-first-section-copyright {
		font-size: 14px;
		padding-bottom: 1rem;
	}

	.footer-first-section-social-icon {
		height: 3rem;
		padding-left: 0px;
	}

}

.stat-card-link {
	text-decoration: none;
}

.request-mentor-lg {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 1rem;
}

.request-mentor-sm {
	display: none;
}

@media screen and (max-width: 768px) {
	.request-mentor-lg {
		display: none;
	}

	.request-mentor-sm {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin: 1rem 2rem 0;
		padding-bottom: 5rem;
	}
}

.request-class-box {
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.request-class-btn {
	border-radius: 10px;
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	font-weight: 700;
}

.coupon-code-box {
	padding: 1rem 0;
	height: auto;
	margin-top: 1rem;
}

.coupon-code-box-heading {
	/* text-align: justify; */
	padding-top: 0.25rem;
	font-size: 1.15rem;
	font-weight: 600;
	padding-left: 10px;
}

.single-coupon-wrapper {
	border-bottom: 1px solid #444;
	padding-bottom: 5px;
	padding-top: 20px;
}

.single-coupon-wrapper:last-child {
	border-bottom: none;
}

.coupon-description {
	padding-left: 10px;
	color: var(--fontColor-secondary);
}

.coupon-code-box-height {
	/* height: 295px; */
	/* overflow-y: hidden; */
	border-radius: 30px;
}

.social-login-pop {
	padding: 0px 40px 20px 40px;
	text-align: center;
	/* border-top: 1px solid #eeeeee; */
}

.google-button {
	width: 100%;
	border: 2px solid var(--border);
	color: var(--fontColor-primary);
	padding: 14px 0px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 10px;
}

.google-button:hover {
	text-decoration: none;
	color: inherit;
	color: white;
	background: var(--background_gradient);
}

.google-button-login-page {
	text-align: center;
	width: 100%;
}

.social-text-or-placement {
	margin-top: 2rem;
}

.social-padding-top {
	padding-top: 20px;
}

.social-text-placement {
	margin: 2rem 0;
}

.facebook-button {
	margin-top: 20px;
}

.interest-popup-wrapper {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(3, 1fr);
	color: var(--fontColor-primary);
	/* background-color: var(--background_secondary); */
	place-items: center;
}

.interest-div-student, .interest-div-student-ticked {
	width: 180px;
	height: 100px;
	border: 1px solid var(--fontColor-secondary);
	border-radius: 10px;
	padding: 10px;
	text-decoration: none;
	font-weight: 600;
	color: var(--fontColor-primary);
	word-wrap: break-word;
	transition: 0.3s ease;
	cursor: pointer;
}

.interest-div-student-ticked {
	background-color: var(--background_tertiary);
	box-shadow: var(--shadow_primary);
}

.interest-div-student>p {
	position: relative;
	top: 58%;
	word-wrap: break-word;
	font-size: 14px;
}

.interest-div-student-ticked>p {
	position: relative;
	top: 58%;
	word-wrap: break-word;
	font-size: 14px;
}


@media only screen and (max-width: 575px) {
	.interest-popup-wrapper {
		grid-template-columns: repeat(2, 1fr);
	}

	.interest-div-student, .interest-div-student-ticked {
		width: 150px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.interest-div-student>p {
		position: static;
		text-align: center;
	}

	.interest-div-student-ticked>p {
		position: static;
		text-align: center;
	}
}

.interest-saving-text>.swal-title {
	font-size: 1.25rem;
	padding: 0 3vw;
}

.swal-interest .swal-content {
	padding: 0px !important;
	margin-top: 0px !important;
}

.swal-interest {
	/* width: 80%; */
	max-width: 700px;
	border-radius: 5px !important;
	background-color: transparent !important;
}

.interest-row {
	margin: clamp(10px, 2vw, 20px) 0;
}

.interest-image {
	--wd-ht: clamp(50px, 10vw, 100px);
	height: var(--wd-ht);
	width: var(--wd-ht);
	border-radius: 50%;
}

.interest-image-opacity {
	opacity: 0.4;
}

.interest-tick-mark {
	position: absolute;
	height: 100%;
	width: 90%;
	transition: 0.3s ease;
	background-color: var(--background_tertiary);
	font-size: 50px;
	border-radius: 1rem;
}

.interest-tick-mark::before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.interest-tick-mark-hide {
	display: none;
}

.edit-interest-btn-popup {
	display: block;
	padding: 10px 35px 10px 35px;
	border: none;
	color: #fff;
	border-radius: 10px;
	background-color: #ff5938;
	font-size: 16px;
	margin: 0 auto;
}

.interest-button-info-1 {
	font-size: 1rem;
	color: var(--fontColor-secondary) !important;
	padding-left: 20px;
}

.interest-button-info {
	font-size: 1rem;
	color: var(--fontColor-primary) !important;
	padding-left: 20px;
}

.form-floating {
	text-align: left;
}

@media only screen and (max-width:768px) {
	.profile-popup-header, .interest-button-info-1 {
		padding: 0;
	}

	.profile-popup-header {
		font-size: 1.5rem;
	}

	.swal-interest {
		width: 100%;
		border-radius: 5px !important;
		background-color: transparent !important;
	}

	.edit-interest-btn-popup {
		display: block;
		padding: 10px;
		width: 150px;
		border: none;
		color: #fff;
		border-radius: 10px;
		background-color: #ff5938;
		font-size: 16px;
		margin: 0 auto;
	}
}

.edit-profile-interest-button {
	padding: 0 2rem;
	display: block;
	border: none;
	border-radius: 10px;
	font-size: 16px;
	background: none;
	border: 2px solid var(--border);
}

.mv-8 {
	margin: 8px 0 !important;
}

.mh-8 {
	margin: 0 8px !important;
}

.mt-16 {
	margin-top: 16px;
}

.mt-24 {
	margin-top: 24px;
}

.mt-32 {
	margin-top: 32px;
}

.mb-16 {
	margin-bottom: 16px;
}

.mb-24 {
	margin-bottom: 24px;
}

.mb-32 {
	margin-bottom: 32px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

input[type="tel"] {
	background: var(--background_secondary) !important;
}

.upcoming-exp-dashboard-div .react-multi-carousel-item {
	width: auto !important;
}

.upcoming-exp-dashboard-div .experience-snippet-dashboard {
	width: 93%;
	margin: 0 auto;
}

@media (max-width: 576px) {
	.upcoming-exp-dashboard-div .experience-snippet-dashboard {
		width: 98%;
	}
}

.upcoming-exp-dashboard-div .react-multi-carousel-track {
	overflow-x: scroll !important;
}

.upcoming-exp-dashboard-div .custom-carousel {
	margin-top: 20px;
	display: flex;
	width: 100%;
	overflow-x: scroll;
}

.refund-popup-content {
	padding: 1rem 1.25rem;
	max-width: 400px;
	background-color: var(--background_tertiary);
	color: var(--fontColor-primary);
}

.notifications-popup-content {
	max-width: 400px;
	background-color: var(--background_primary);
	color: var(--fontColor-secondary);
	box-shadow: var(--shadow_primary);
}

.refund-popup-hero-head {
	color: var(--fontColor-primary);
}

.refund-popup-head {
	font-weight: bold;
}

.course-type-ribbon {
	color: white;
	background-color: #8d00c4;
	border-radius: 0.5rem 0 0 0;
	left: -0.5rem;
}

.course-type-ribbon::after {
	position: absolute;
	content: "";
	left: 0;
	top: 1.5rem;
	height: 0.75rem;
	width: 0.5rem;
	background-color: #ae63cc;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
}

.course-type-ribbon::before {
	position: absolute;
	content: "";
	right: -0.75rem;
	height: 1.5rem;
	width: 0.75rem;
	background-color: #8d00c4;
	clip-path: polygon(100% 0, 0 50%, 100% 100%, 0 100%, 0 0%);
}

/* 
.dark-mode-toggle {
	position: fixed;
	bottom: 1rem;
	left: 0;
} */

.dark-mode-toggle button {
	padding: 0.1rem;
	height: 30px;
	width: 30px;
	font-size: 1.25rem;
	border: 0;
	cursor: pointer;
	border-radius: 50%;
}

.page-item {
	box-shadow: var(--shadow_primary);
	border-radius: 50%;
	margin: 0 0.15rem;
}

.page-item .page-link {
	background: var(--background_secondary) !important;
}

.page-item.active .page-link {
	color: var(--fontColor-primary);
}

.zoom-nolink-popup {
	max-width: 300px;
	margin: auto;
}

.single-webinar-container {
	height: 100vh;
	position: fixed;
	top: 0;
	left: 50%;
	width: min(900px, 100%);
	transform: translateX(-50%);
	background-color: var(--background_primary);
	overflow-y: scroll;
	scrollbar-width: thin;
	z-index: 100;
}

.single-webinar-container::-webkit-scrollbar {
	display: none;
}

.single-webinar-container {
	-ms-overflow-style: none;
}

.single-webinar-container a {
	text-decoration: none;
	color: inherit;
}

.single-webinar-container p {
	margin: 0;
}

.single-webinar-close-btn,
.pubnub-back-btn {
	border-radius: 50%;
	position: fixed;
	z-index: 101;
	padding: 0;
	top: 1rem;
	left: 1rem;
	width: 1.5rem;
	height: 1.5rem;
	color: white;
}

.pubnub-back-btn {
	display: none;
}

@media screen and (max-width: 700px) {
	.pubnub-back-btn {
		display: block;
		position: relative;
	}
}

.single-webinar-img {
	width: 100%;
	height: 100%;
	max-height: 400px;
}

.single-webinar-content {
	padding: 1rem 1.5rem;
	margin-bottom: 3.75rem;
}

.single-webinar-content h1 {
	margin-bottom: 1rem;
}

.webinar-hosted-by {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-bottom: 1rem;
}

.webinar-hosted-by>div {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	margin-bottom: 0.75rem;
}

.webinar-hosted-by img {
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
}

.webinar-hosted-by p:last-of-type i {
	margin-left: 0.5rem;
	margin-right: 1.25rem;
}

.single-webinar-actions {
	position: fixed;
	bottom: 0;
	left: 50%;
	width: min(900px, 100%);
	transform: translateX(-50%);
	margin: auto;
	z-index: 100;
}

.single-webinar-backdrop {
	display: block;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 768px) {
	.webinar-container .snippet-image-mobile-webinar .react-multi-carousel-list {
		border-radius: 0;
	}

	.webinar-container .snippet-image-mobile-webinar .snippet-img-exp-page {
		border-radius: 0;
		margin: 0;
		padding: 0;
		width: 100vw !important;
	}

	.webinar-basic-info-container {
		margin: 0 -24px;
	}
}

.carousel-dots .react-multi-carousel-dot button {
	background: gray;
	width: 0.5rem;
	height: 0.5rem;
}

.carousel-dots .react-multi-carousel-dot--active button {
	background: var(--border) !important;
}

@media screen and (min-width: 768px) {
	.carousel-dots {
		display: none;
	}
}

.class-type-text,
.club-type-text {
	margin: 0 0 0.4rem;
	padding: 0;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	font-size: 0.9rem;
}

.image-lazyload {
	width: 100%;
}

.sticky-price-box {
	position: sticky;
	top: 40px;
}

.feed-skeleton {
	width: 500px;
	height: 300px;
}

.otp-box input {
	background: transparent !important;
	outline: 0;
	border: 0;
	border-bottom: 2px solid;
	border-radius: 0px;
	width: 50px !important;
	height: 50px;
	margin-right: 10px;
}

@media only screen and (max-width:768px) {
	.feed-skeleton {
		width: 300px;
		height: 200px;
	}

	.otp-box input {
		width: 40px !important;
		height: 50px;
		margin-right: 10px;
	}
}

.mobile-bottom-nav {
	display: none !important;
}

.mentorModal-title {
	position: relative;
	bottom: 70px;
	left: 70px;
	width: 80%;
}

.mentorModalFooter {
	margin-top: -30px;
}

@media only screen and (max-width:600px) {
	.mobile-scroll-menu {
		display: none !important;
	}

	header {
		position: sticky;

	}

	.mentorModalContiner {
		margin-top: -40px
	}

	.mentorModal-title {
		position: relative;
		bottom: 70px;
		left: 10px;
		width: 80%;
	}


	.header-div {
		padding: 0;
	}

	.mobile-bottom-nav {
		display: block !important;
		position: fixed;
		bottom: -5px;
		background: var(--background_primary);
		color: var(--fontColor-primary);
		padding: 10px 20px;
		width: 100%;
		box-shadow: -2px -2px 10px -8px;
		z-index: 9999;
		height: 65px;
	}

	.mobile-bottom-nav-container {
		display: flex;
		justify-content: space-between;
		overflow: hidden !important;
	}

	.mobile-bottom-nav-container-no-login {
		display: flex;
		overflow: hidden !important;
	}

	.mobile-bottom-nav-item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.mobile-bottom-nav-item img {
		width: 20px;
	}

	.mobile-bottom-nav-item a {
		color: var(--fontColor-primary);
		text-decoration: none;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.mobile-bottom-nav-item p {
		margin: 0;
		font-size: 14px;
	}

}

.club-dash {
	min-height: 333px;
}

@media only screen and (max-width:1300px) {
	.club-dash {
		min-height: 290px;
	}

}

@media only screen and (max-width:768px) {
	/* .club-dash {
		min-height: auto;
	} */
}

.gopher-font {
	font-family: "Gopher-Regular" !important;
}

.world-event {
	padding: 10px 20px !important;
	border-radius: 30px !important;
}

.btn-boo {
	padding: 10px 16px;
	box-shadow: var(--shadow_primary);
	border-radius: 30px;
	cursor: pointer;
	font-weight: 700;
}

.font-color-primary {
	color: var(--fontColor-primary) !important;
}

.font-color-secondary {
	color: var(--fontColor-secondary) !important;
}

.card-link {
	text-decoration: none;
	color: inherit;
	transition: all 0.3s ease;
}

.card-link:hover {
	text-decoration: none;
	color: inherit;
	transform: scale(1.03);
}

.hide-scroll-bar::-webkit-scrollbar {
	display: none;
}
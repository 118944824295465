.webinar_col {
    height: 100%;
}

.room_container {
    padding: 0 clamp(2rem, 5vw, 150px);
}

@media only screen and (max-width: 768px) {
    /* .webinar_col {
        padding: 0;
    } */

    .room_container {
        padding: 0;
    }
}

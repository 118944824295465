.experience_snippet_inner {
	margin: 0.5rem;
	border-radius: 40px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
	background-color: var(--background_secondary);
	transition: 0.2s ease-in-out;
}

.experience_snippet_inner:hover {
	transform: scale(1.05);
}

.snippet_image {
	width: 100%;
}

.snippet_img_snippets {
	overflow: hidden;
	height: 180px;
	border-radius: 40px 40px 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.snippet_content {
	padding: 1rem 1.25rem;
	margin-top: 0.5rem;
}

.snippet_content a {
	text-decoration: none;
}

.cardOuter{
	margin: 20px 0;
	padding: 0 30px;
}


.cardLink {
	box-shadow: rgb(255, 255, 255) 0px 10px 10px 2px;
	text-decoration: none;
	cursor: pointer;
	border-radius: 21px;
	transition: 0.4s;
	/* background-color: #0c4cee; */
  }
  
  .Card {
	border-radius: 21px;
	padding: 0;
	color: #f5f5fd;
	font-family: sans-serif;
	transition: 0.4s;
	/* box-shadow: inset -0px -270px 70px 0px #051848; */
	box-shadow: inset -0px -270px 70px 0px var(--background_primary);
	background-color: #0c4cee!important;
	border: 0;
	min-height: 480px;
	font-family: TTNorms-Black;
	text-align: justify;
	color: var(--fontColor-primary);
  }
  
  .Card:hover {
	transform: rotate(1deg);
  }
  
  .cardHeader img {
	width: 100%;
	border-radius: 10px;
	box-shadow: #fff 0px 1px 50px 1px;
	box-shadow: #fff 0 8px 15px -8px;
	opacity: 0.85!important;
	height: 220px;
  }

  
  .card-body p {
	font-size: 16px;
  }
  
  .courseTypeRibbon {
	color: white;
	background-color: #435d16;
	background-color: #181d0fbd;
	border-radius: 0.5rem 0 0 0;
	left: -0.5rem;
	z-index: 10;
  }
  
  .courseTypeRibbon::after {
	position: absolute;
	content: "";
	left: 0;
	top: 1.5rem;
	height: 0.75rem;
	width: 0.5rem;
	background-color: #435d16;
	background-color: #181d0fbd;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
  }

  @media only screen and (max-width:1000px){
	.cardOuter{
		padding: 0 20px;
	}
  }
  
  @media only screen and (max-width:500px){
	.cardOuter{
		padding: 0 10px;
	}
  }
  
.carousel-item {
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 2rem;
	display: flex;
	height: 240px !important;
	color: white;
}

@media (max-width: 768px) {
	.carousel-item {
		height: 200px !important;
	}
}

.course-item {
	font-size: 0.9rem;
	align-self: flex-end;
}

.course-item a {
	color: inherit;
}

.course-item h5,
.course-item p {
	margin-bottom: 0.25rem;
}

.author {
	display: flex;
	gap: 0.75rem;
	align-items: center;
	font-size: 1.1rem;
}

.author img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.25rem;
}

.pagination ul {
	gap: 0.5rem;
	flex-wrap: wrap;
	justify-content: center;
}

.pagination li a {
	width: 2.75rem;
	height: 2.75rem;
	border: 0;
	border-radius: 50%;
	color: var(--fontColor-secondary);
	background-color: var(--background_tertiary) !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pagination li:first-child a,
.pagination li:last-child a {
	border-radius: 50% !important;
}

.pagination li a:hover {
	color: var(--fontColor-primary) !important;
}

.dropdown {
	border-color: var(--border) !important;
	box-shadow: none !important;
}

.dropdown-menu {
	inset: 0.5rem auto auto auto !important;
	background-color: var(--background_secondary) !important;
	text-align: center;
	padding: 0.25rem 0.5rem;
	border: 1px solid var(--border);
	border-radius: 1rem;
}

.dropdown-menu button {
	border-radius: 10px;
	margin: 0.25rem 0;
	color: var(--fontColor-primary) !important;
	background: var(--background_tertiary) !important;
	border: 1px solid var(--fontColor-secondary);
}

.is-active {
	border: 1px solid var(--border) !important;
}

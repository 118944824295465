.chatContainer {
    display: flex;
    background: var(--background_primary);
}

.channelsList {
    width: 300px;
    padding: 30px 20px;
    max-height: 100vh;
    overflow-y: auto;
    padding-top: 0;
}

.channelsList::-webkit-scrollbar {
    display: none;
}

.channelsList::-webkit-scrollbar-track {
    background: transparent;
}

.channelsListHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background: var(--background_primary);
    height: 66px;
}

.channelsListHead button {
    background: transparent;
    cursor: pointer;
}

.channelsListHead h4 {
    margin: 0;
}

.messages {
    flex-grow: 1;
    margin-left: 10px;
}

.header {
    display: flex;
}

.header .info {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    right: 10px
}

.header .info .left .image img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.header .info .right h6,
.header .info .right p {
    margin: 0;
}

.channels {
    margin-top: 20px;
}

.channels .channel, .channels .activeChannel {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    background-color: var(--background_primary);
    box-shadow: var(--shadow_primary);
    border-radius: 30px;
    cursor: pointer;
}

.channels .activeChannel {
    border: 1px solid var(--border);
}

.channels .channel .image img,
.channels .activeChannel .image img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.noChannels {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.hamburger {
    display: none;
    position: relative;
    right: 10px;
}

.closeBtn {
    display: none;
}

.channelHeadLeft, .channelHeadRight{
    display: flex;
    gap: 10px;
    align-items: center;
}

@media only screen and (max-width:768px) {
    .chatContainer {
        display: block;
    }

    .channelsList {
        width: 100%;
        height: 100vh;
    }

    .messages {
        flex-grow: 0;
        margin-left: 0;
    }

    .hamburger {
        display: block;
        color: #000;
    }

    .closeBtn {
        display: block;
    }

    .closeBtn img{
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }

    .hamburger img{
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

}
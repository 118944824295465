.activity {
	background-color: var(--background_primary);
	border-radius: 30px;
	padding: 0 1.25rem;
	max-width: 650px;
	overflow: hidden;
}

.individual-activity {
	padding: 1rem;
	border-bottom: 1px solid #8c8c926e;
	margin-top: 5px!important;
}

.individual-activity:last-child {
	border-bottom: none;
}

.subscribeButton {
	margin: 2rem 0;
	border-radius: 10px;
	width: fit-content;
	font-size: 1.25rem;
	padding: 0.5rem 2rem;
}

.userFeedContainerMid {
	width: 50% !important;
}


@media only screen and (max-width:1275px) {
	.userFeedSidebar {
		display: none!important;
	}

	.userFeedContainer {
		justify-content: center;
		margin: 0;
		padding: 0;

	}

	.userFeedContainerMid {
		width: 100%!important;
	}
}

@media only screen and (max-width:500px) {
	.individual-activity {
		padding: 1rem 5px;
	}

	.activity {
		overflow-x: hidden;
		padding:0;
	}

	.user-feed-container {
		margin-top: 50px !important;
		padding: 0 !important;
	}
}

@media only screen and (max-width:375px) {
	.individual-activity {
		padding: 1rem 2px;
	}

	.activity {
		padding: 0 0.9rem;
	}
}
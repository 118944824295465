.card {
	position: relative;
	background-color: var(--background_primary);
	border-radius: 24px;
	overflow: hidden;
	box-shadow: var(--shadow_primary);
	margin: 1rem 0.5rem 2rem;
	transition: 0.3s ease-in-out;
}

.card:hover {
	transform: scale(1.05);
}

.card p {
	color: var(--fontColor-secondary);
}

.card a {
	text-decoration: none;
	color: var(--fontColor-primary);
}

.card p {
	margin: 0.25rem 0 0;
}

.banner {
	width: 100%;
	object-fit: cover;
	height: 200px;
	max-height: 200px;
}

.file-type,
.dwn-icon {
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	background: var(--background_gradient);
	padding: 0.1rem 0.75rem;
	border-radius: 24px;
	color: white !important;
	margin: 0;
	z-index: 1;
}

.dwn-icon {
	background: none;
	top: 100px;
	left: 50%;
	font-size: 50px;
	transform: translateX(-50%);
	opacity: 0;
	transition: 0.3s ease-in-out;
}

.card:hover .dwn-icon {
	opacity: 1;
}

.card:hover .banner {
	filter: brightness(0.5);
}

.cardOuter{
  margin-bottom: 30px;
}

.card {
  box-shadow: var(--shadow_primary);
  background: linear-gradient(45deg, var(--background_primary), var(--background_secondary));
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 30px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.4s;
  display: block;
  color: var(--fontColor-primary);
  min-height: 430px;
  max-width: 400px;
  margin: auto;
}

.card:hover {
  color: var(--fontColor-primary);
}

.cardImg {
  overflow: hidden;
  border-radius: 20px
}

.card:hover .cardImg img {
  transform: scale(1.05);
}

.cardImg img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 20px;
  transition: 0.3s ease
}

.cardContent {
  padding: 15px 10px;
}

.cardContent h5 {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.features {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0;
}

.feature {
  padding: 5px 10px;
  box-shadow: var(--shadow_primary);
  border-radius: 30px;
  font-size: 14px;
}

.cardContent p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--fontColor-secondary);
  word-wrap: break-word;
}

.mentor{
  display: flex;
  align-items: center;
  gap: 10px;
}

.mentor img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.mentor h6{
  margin: 0;
}

@media only screen and (max-width:500px) {

  .features {
    gap: 5px;
  }

  .feature {
    font-size: 12px;
  }

}
.worldImage {
    height: 55px;
    width: 55px;
    border-radius: 50%;
}

.worldItemLeft {
    display: flex;
    flex: 1;
}

.worldDetails {
    margin-left: 10px;
}

.worldDetails h6 {
    font-size: 0.82rem;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}

.worldDetails p {
    font-size: 12px !important;
    text-align: justify;
    font-weight: 700!important;
}

.worldItemRight a {
    color: #56ccf2;
    text-decoration: none;
    font-size: 14px;
}

.worldItemRight p {
    color: var(--fontColor-primary)!important;
}

.worldItemRight p:hover {
    color: #56ccf2;
}
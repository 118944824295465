.content .details {
    background-color: var(--background_primary);
    box-shadow: var(--shadow_secondary);
    padding: 1.2rem 1.5rem;
    border-radius: 24px;
    margin-top: 2rem;
}

.content .listItems {
    display: flex;
    list-style: none;
    gap: 20px;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.content .listItems li {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
}

.content .listItems p {
    color: var(--fontColor-secondary);
}

.description, .pricing {
    background-color: var(--background_primary);
    box-shadow: var(--shadow_secondary);
    padding: 1.2rem 1.5rem;
    border-radius: 24px;
    margin-top: 2rem;
}

.batches {
    margin-top: 1rem;
    padding: 5px;
}

.batch{
    box-shadow: var(--shadow_secondary);
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.batch .header, .batch .body{
    padding: 10px 15px;
    border-bottom: 1px solid #8b8e915d;
}

.batch .footer{
    padding: 10px 15px;
}

.batch .footer button{
    width: 100%;
    display: block;
    margin: 0 auto;
}

.batchItem h6{
    margin: 0;
    font-size: 14px;
}

.batchItem {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}
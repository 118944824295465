.author {
	align-items: center;
	justify-content: space-between;
}

.author-details {
	display: flex;
	gap: 1rem;
}

.author-details p,
.author-details h6 {
	margin: 0;
}

.author-details h6 {
	font-size: 1.25rem;
}

.author-details img {
	width: 55px;
	height: 55px;
	border-radius: 50%;
}

.author-details div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.author-details div p {
	color: var(--fontColor-secondary);
}

.author button {
	background: none;
	padding: 0.25rem 1rem;
	border: 2px solid var(--border);
	border-radius: 10px;
}


.activity-image {
	display: flex;
}

.activity-image img {
	width: 100%;
	border-radius: 1rem;
	object-fit: cover;
	padding: 0 0.3rem;
	margin: 10px 0;
}

.activity-image iframe {
	width: 578px;
	height: 320px;
}

.description {
	border-bottom: 3px solid var(--background_secondary);
	margin: 0 1rem;
	padding: 1rem 0rem;
}

.liked p {
	padding: 0 10px;
	margin: 10px 0;
	color: var(--fontColor-secondary);
	cursor: pointer;
}

.stats {
	padding-bottom: 1rem;
	color: var(--fontColor-secondary);
	display: flex;
	align-items: center;
}

.stats p {
	margin: 0;
	padding: 0 0.3rem;
}

/* .stats p:first-of-type {
	flex: 1;
} */

.self-comment {
	display: flex;
	/* align-items: center; */
	gap: 1rem;
	padding: 0.25rem 0;
	height: 60px;
}

.comments img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.comments p {
	margin: 0;
}

.self-comment input {
	padding: 0.5rem 1rem;
	border-radius: 50rem;
	border: none;
	width: 100%;
}

.other-comments {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 0.25rem 0;
}

.other-comments p strong {
	color: #56ccf2;
}

.time {
	color: var(--fontColor-secondary)
}

.time::before {
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: var(--fontColor-primary);
	color: var(--fontColor-primary);
	display: inline-flex;
	margin: 0 0.4rem;
	position: relative;
	bottom: 2px;
}

.clubName {
	position: relative;
	left: 73px;
	bottom: 19px;
	color: var(--fontColor-secondary);
	margin: 0;
	font-weight: 700;
}

@media only screen and (max-width:1300px) {
	.activity-image iframe {
		width: 550px;
		height: 300px
	}

}

@media only screen and (max-width:600px) {
	.author-details h6 {
		font-size: 1rem !important;
	}

	.activity-image iframe {
		max-width: 400px;
		height: 260px
	}

	.clubName {
		font-size: 14px;
	}
}

@media only screen and (max-width:450px) {
	.activity-image iframe {
		max-width: 100%;
		height: 260px
	}

}
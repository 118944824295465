.carousel-container {
	padding: 12px 0 40px;
}

.card-container {
	position: relative;
	border-radius: 1.5rem;
	margin: 0 10px;
	overflow: hidden;
}

.card-img {
	width: 100%;
	border-radius: 1.5rem;
	z-index: -1;
}

.details {
	margin-top: 20px;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	color: var(--fontColor-primary);
}

.details p {
	margin: 0;
	margin-bottom: 0.25rem;
}

.details img {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	margin-right: 0.5rem;
}

@media screen and (max-width: 700px) {
	.card-img {
		width: 85vw !important;
		margin-left: -11px;
	}
	.carousel-container {
		padding: 12px 0 60px;
	}
}

.mentorContainer {
    max-width: 1000px;
    padding-top: 20px;
    padding-bottom: 50px;
}

.leftSticky {
    position: sticky;
    top: 120px;
}

.imgBox {
    position: relative;
    margin-bottom: 30px;
}

.imgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
}

.imgBox h5 {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    left: 15px;
    text-transform: capitalize;
    background-color: var(--background_primary);
    box-shadow: var(--shadow_primary);
    padding: 10px 20px;
    border-radius: 30px;
    opacity: 0.7;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.shareBtn {
    height: 45px;
    min-width: 140px;
    border-radius: 10px;
    background: #e9e9e9 !important;
    color: #000;
}

.shareBtnInner {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.shareBtnInner img {
    width: 30px;
    height: 30px;
}

.msgBtn {
    height: 45px;
    min-width: 140px;
    border-radius: 10px;
    color: #fff;
}

.intro {
    box-shadow: var(--shadow_primary);
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 40px;
    background: var(--background_primary);
}

.intro h5 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.intro p {
    font-size: 15px;
    font-weight: 400;
}

.tabBox {
    padding: 30px;
    margin-top: 30px;
    box-shadow: var(--shadow_primary);
    border-radius: 30px;
    background: var(--background_primary);
}

.noWorlds {
    text-align: center;
    background: var(--background_primary);
}

.tabBoxWorlds {
    padding: 30px;
    margin-top: 30px;
    box-shadow: var(--shadow_primary);
    border-radius: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: var(--background_primary);
}

.card {
    box-shadow: var(--shadow_primary);
    background: linear-gradient(45deg, var(--background_primary), var(--background_secondary));
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.4s;
    display: block;
    color: var(--fontColor-primary);
    min-height: 348px;
    max-width: 270px;
    margin: auto;
    margin-bottom: 30px;
}

.card:hover {
    color: var(--fontColor-primary);
}

.cardImg {
    overflow: hidden;
    border-radius: 20px
}

.card:hover .cardImg img {
    transform: scale(1.05);
}

.cardImg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
    transition: 0.3s ease
}

.cardContent {
    padding: 15px 10px;
}

.cardContent h5 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}

.features {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0;
}

.feature {
    padding: 5px 10px;
    box-shadow: var(--shadow_primary);
    border-radius: 30px;
    font-size: 14px;
}

.cardContent p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    color: var(--fontColor-secondary);
    word-wrap: break-word;
    font-size: 14px;
}

@media only screen and (max-width:768px) {
    .leftSticky {
        position: static;
    }

    .tabBox {
        padding: 15px;
    }

    .tabBoxWorlds {
        justify-content: center;
    }

    .card {
        max-width: 100%;
    }

    .shareBtn, .msgBtn {
        min-width: 160px;
    }
}
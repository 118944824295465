.errorpg {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100vh;
	background-color: white;
	color: black;
	padding: 2rem;
}

.errorpg h1,
.errorpg a {
	color: #ff8d59;
}

.errorpg h1 {
	font-size: 9rem;
}

.errorpg a {
	text-decoration: dashed underline;
}

.errorpg p:first-of-type {
	font-size: 2rem;
}
